import { Link } from 'components/common/Link';
import { SmallMenuItemProps } from './HamburgerMenu.types';
import * as S from './SmallMenuItem.styles';

export const SmallMenuItem = ({
  href,
  onClick,
  red,
  target = '_self',
  tabAble,
  label,
  hidden,
  Icon,
}: SmallMenuItemProps) => {
  if (hidden) {
    return null;
  }

  if (!href) {
    return (
      <S.SmallMenuItem onClick={onClick} red={red} tabIndex={tabAble} data-cy={`${label} Menu Item`}>
        {Icon}
        {label}
      </S.SmallMenuItem>
    );
  }

  return (
    <Link prefetch={false} href={href} passHref target={target}>
      <S.SmallMenuItem onClick={onClick} red={red} tabIndex={tabAble} data-cy={`${label} Menu Item`}>
        {Icon}
        {label}
      </S.SmallMenuItem>
    </Link>
  );
};
