import { Add } from '@hiberworld/icons';
import { CollapsibleTextButton } from 'components/common/buttons/CollapsibleTextButton';
import styled from 'styled-components';

export const CreateButton = styled(CollapsibleTextButton).attrs({
  role: 'button',
  variety: 'primary',
  size: 'medium',
  dataCy: 'topBarCreateButton',
  Icon: <Add title="Create World" size={20} />,
  title: 'Create world',
  text: 'CREATE WORLD',
})`
  height: 36px;
  border-radius: 100px;
  margin: 0 2px;

  @media (min-width: ${({ theme }) => theme.breakpoint.medium}) {
    margin: 0;
  }
`;
