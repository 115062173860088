import { FlexBox } from 'components/layouts/FlexBox';
import styled from 'styled-components';
import { TextInputStyleProps } from './SearchInput.types';

export const TextInput = styled.input.attrs({ type: 'text', enterKeyHint: 'go' })<TextInputStyleProps>`
  background: ${({ backgroundColor }) => backgroundColor};
  border-radius: ${({ borderRadius = 20 }) => borderRadius}px;
  border: ${({ borderColor }) => borderColor ?? 'none'};
  width: 100%;
  outline: none;
  color: ${({ theme }) => theme.colors.input.search.text};
  padding-right: 2rem;
  padding-left: ${props => (props.hasIcon ? '2.1rem' : '1rem')};
  height: inherit;
  font-size: ${({ theme }) => theme.typography.body.medium.size.web};
  font-weight: ${({ theme }) => theme.typography.body.medium.weight};
  line-height: ${({ theme }) => theme.typography.body.medium.lineHeight};
  font-style: ${({ theme }) => theme.typography.body.medium.style};

  transition: border 0.25s ease-in-out, color 0.25s ease-in-out;

  &:focus {
    border: ${({ theme }) => theme.colors.input.search.focusBorder};
    color: ${({ theme }) => theme.colors.input.focus.text};
  }

  &::placeholder {
    color: ${({ theme }) => theme.colors.input.search.placeholder};
  }
`;

export const TextInputContainer = styled.div`
  height: 40px;
  position: relative;

  div:first-of-type path {
    transition: fill 0.25s ease-in-out;
  }

  :has(input:focus) div:first-of-type path {
    fill: white;
  }
`;

export const IconWrapper = styled.div<TextInputStyleProps>`
  position: absolute;
  height: inherit;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 0.5rem;
  color: ${({ theme }) => theme.colors.input.search.placeholder};
  pointer-events: none;
`;

export const RightIcon = styled(FlexBox)`
  position: absolute;
  right: 0;
  top: 0;
  width: 2rem;
  height: 100%;
  align-items: center;
  justify-content: center;
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};
  color: ${props => props.theme.colors.input.search.placeholder};

  &:hover {
    color: ${props => props.theme.colors.input.search.text};
  }

  transition: color 0.25s ease-in-out;
`;
