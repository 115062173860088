import { Label } from 'components/typography';
import { useTheme } from 'styled-components';
import { capitalizeFirstLetterInSentence } from 'utils/capitalize';

export const ErrorMarker = ({ message }: { message: string }) => {
  message = capitalizeFirstLetterInSentence(message);
  const theme = useTheme();

  return (
    <Label size="medium" color={theme.colors.text.error}>
      {message}
    </Label>
  );
};

export default ErrorMarker;
