import { FlexBox } from 'components/layouts/FlexBox';
import * as S from './HamburgerButton.styles';

type HamburgerButtonProps = {
  expanded: boolean;
  onClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  showNotificationIndicator?: boolean;
};

export const HamburgerButton = ({ expanded, onClick, showNotificationIndicator }: HamburgerButtonProps) => (
  <S.HamburgerWrapper title="Expand Menu" data-cy="topBarHamburgerMenu" onClick={onClick}>
    {expanded ? (
      <S.CloseMenu role="button" size={40} title="Close Menu" />
    ) : (
      <S.OpenMenu size={40} role="button" title="Open Menu" />
    )}
    {!expanded && showNotificationIndicator ? (
      <FlexBox
        data-testid="showNotificationIndicator"
        position="absolute"
        right="4px"
        width="10px"
        height="10px"
        backgroundColor="red"
        borderRadius="8px"
      />
    ) : null}
  </S.HamburgerWrapper>
);
