import { config } from 'config';
import { FlexBox } from 'components/layouts/FlexBox';
import { useEffectOnce } from 'lib/useOnMount';
import { LegacyRef, useRef, useState } from 'react';
import { loadReCaptcha, ReCaptcha } from 'react-recaptcha-v3';
import { Spinner } from '../common/spinners/Spinner/Spinner';

const captchaKey = config.captcha.key;

export interface RecaptchaElement {
  execute: () => void;
}

export type OnLoadParams = { response: string | null; recaptchaElement: RecaptchaElement | null };

export interface RecaptchaProps {
  onLoad: (args: OnLoadParams) => void;
}

type Status = 'loading' | 'initial' | 'ready' | 'error' | 'success';

export const Recaptcha = ({ onLoad }: RecaptchaProps) => {
  const [status, setStatus] = useState<Status>('initial');
  const ref = useRef<RecaptchaElement>();

  const handleOnLoad = (response: string) => {
    setStatus('ready');
    if (ref.current) {
      onLoad({ response, recaptchaElement: ref.current });
    }
  };

  useEffectOnce(() => {
    if (typeof window !== 'undefined') {
      setStatus('loading');
      loadReCaptcha(captchaKey);
    }
  });

  return (
    <FlexBox justifyContent="center" alignItems="center">
      <ReCaptcha
        ref={ref as LegacyRef<ReCaptcha>}
        verifyCallback={handleOnLoad}
        render="explicit"
        sitekey={captchaKey}
        action="default"
      />
      {status === 'loading' && (
        <FlexBox gap="10" width="100%" direction="column" alignItems="center" justifyContent="center">
          <Spinner />
          <span>Loading captcha...</span>
        </FlexBox>
      )}
    </FlexBox>
  );
};
