import { LootType, useLootQuery } from 'generated/graphql';
import { useMe } from 'hooks/useMe';

export const useHasUnclaimedLoot = () => {
  const { isLoggedIn } = useMe();
  const loot = useLootQuery({
    variables: { payload: { types: [LootType.Appearance] } },
    skip: !isLoggedIn,
    fetchPolicy: 'cache-and-network',
  });

  return (loot.data?.loot?.length ?? 0) > 0;
};
