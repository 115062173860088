/* eslint-disable sonarjs/no-duplicate-string */
import { LogInOrRegister } from 'components/globals/LogInOrRegister/LogInOrRegister';
import { TopBar } from 'views/global/TopBar/TopBar';
import { Suspension } from 'views/dialogs/SuspensionDialog/Suspension';
import { useMe } from 'hooks/useMe';
import { useSearchParam } from 'lib/useSearchParam';
import dynamic from 'next/dynamic';
import { usePathname } from 'next/navigation';

const Loot = dynamic(() => import('../views/global/Loot'), {
  loading: () => null,
  ssr: false,
});

const PageLoadAuthModal = dynamic(() => import('../components/legacy/modals/AuthModal/useAuthModal'), {
  loading: () => null,
  ssr: false,
});

export const routesWithoutNavigation = [
  '/developer',
  '/ai/prefab-creator',
  '/ai/world-creator',
  '/ai',
  '/hdk',
  '404',
  '/embed',
];

const TopBarContainer = ({ hideNavigation }: { hideNavigation?: boolean }) => {
  const me = useMe();
  const hideNavigationParam = useSearchParam('hideNavigation') === 'true';
  const pathname = usePathname();
  const suspension = (me.me && me.me.suspension) || null;
  const routeWithoutNavigation = routesWithoutNavigation.some(route => pathname.startsWith(route));

  if (hideNavigation || hideNavigationParam || routeWithoutNavigation) {
    return null;
  }

  const { me: user } = me;

  return (
    <>
      <TopBar />
      <PageLoadAuthModal />
      <LogInOrRegister />
      {user && <Loot />}
      {suspension && suspension.seen < 3 && <Suspension suspension={suspension} />}
    </>
  );
};

export default TopBarContainer;
