import { forwardRef } from 'react';
import * as S from './Toggle.styles';
import { ToggleProps } from './Toggle.types';

export const Toggle = forwardRef<HTMLInputElement, ToggleProps>((props: ToggleProps, ref) => {
  return (
    <S.ToggleContainer htmlFor={props.id} backgroundColor={props.backgroundColor}>
      <S.Toggle {...props} id={props.id} type="checkbox" ref={ref} />
    </S.ToggleContainer>
  );
});
