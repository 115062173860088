import { FlexBox } from 'components/layouts/FlexBox';
import { Spacer } from 'components/layouts/Spacer';
import { SoftCurrencyLogo } from 'components/logos/SoftCurrency';
import { Heading } from 'components/typography';
import { FEATURE_TOGGLE_SOFT_CURRENCY } from 'constants/featureToggle.constants';
import { useIsSuspended } from 'hooks/useIsSuspended/useIsSuspended';
import { useMe } from 'hooks/useMe';
import { useFeatureToggle } from 'lib/useFeatureToggles';
import { useRouter } from 'next/router';
import { useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { NotificationsFeed } from 'views/feeds/NotificationsFeed/NotificationsFeed';
import { HamburgerMenu } from 'views/global/HamburgerMenu';
import { CreateButton } from 'views/global/TopBar/CreateButton';
import { HiberLogo } from 'views/global/TopBar/HiberLogo';
import { SearchBar } from 'views/global/TopBar/SearchBar';
import { UserInfo } from 'views/global/TopBar/UserInfo';
import * as S from './TopBar.styles';
import { TOPBAR_HEIGHT } from './TopBar.styles';

const RightSide = styled(FlexBox)`
  gap: 24px;

  @media (max-width: ${({ theme }) => theme.breakpoint.medium}) {
    gap: 4px;
  }
`;

export const TopBar = () => {
  const [showSearch, setShowSearch] = useState(false);
  const { isLoggedIn, me } = useMe();
  const router = useRouter();
  const theme = useTheme();
  const isSuspended = useIsSuspended();
  const softCurrency = useFeatureToggle(FEATURE_TOGGLE_SOFT_CURRENCY);

  const toggleSearch = () => {
    setShowSearch(s => !s);
  };

  const navigateToCreate = () => {
    router.push('/create');
  };

  const hasPublishedProject = Boolean(me?.totalPublishedGames);

  return (
    <>
      <Spacer height={TOPBAR_HEIGHT} />
      <S.Header>
        <FlexBox>
          <HiberLogo data-cy="HiberworldLogo" />
        </FlexBox>
        <FlexBox>
          <SearchBar isOpen={showSearch} />
        </FlexBox>
        <RightSide justifyContent="flex-end" alignItems="center">
          <S.SearchIcon
            size="42px"
            role="button"
            title="search"
            onClick={toggleSearch}
            color={showSearch ? theme.primaryColor.white : theme.primaryColor.appLightGray}
          />
          <CreateButton collapsible={hasPublishedProject} disabled={Boolean(isSuspended)} onClick={navigateToCreate} />

          {isLoggedIn && me && (
            <>
              <NotificationsFeed />
              {softCurrency.isEnabled && (
                <FlexBox gap="4px" alignItems="center" title="HiBux" cursor="default">
                  <SoftCurrencyLogo size={20} />
                  <Heading size="h5">{me.softCurrency}</Heading>
                </FlexBox>
              )}
              <UserInfo
                pictureUrl={me.pictureUrl}
                level={me?.level ?? 1}
                username={me.username}
                progressToNextLevel={(me.currentXp ?? 1) / (me.xpToNextLevel ?? 1)}
              />
            </>
          )}

          <HamburgerMenu />
        </RightSide>
      </S.Header>
    </>
  );
};
