import { Trophy } from '@hiberworld/icons';
import { CircularImage } from 'components/common/images/CircularImage/CircularImage.styles';
import DangerousHtmlContent from 'components/common/DangerousHtmlContent';
import { FlexBox } from 'components/layouts/FlexBox';
import { Link } from 'components/common/Link';
import RelativeDate from 'components/common/RelativeDate';
import { Label } from 'components/typography';
import { useDive } from 'hooks/useDive';
import { useRouter } from 'next/router';
import queryString from 'query-string';
import { useTheme } from 'styled-components';
import { getIcon, getNotificationText, notificationsTypesMap } from './Notification.helpers';
import * as S from './Notification.styles';
import { NotificationProps } from './Notification.types';

export const Notification = ({ closeNotifications, notification, updateVisitedStatus }: NotificationProps) => {
  const router = useRouter();
  const dive = useDive();
  const theme = useTheme();

  const {
    content: { linkTo, image, customData },
    lastUsers,
    type,
    createdAt,
    visited,
  } = notification;

  const { pictureUrl } = lastUsers[0];

  const Icon = getIcon(type, customData?.gameKit);

  const [href, query] = linkTo.href.split('?');
  const queryObj = queryString.parse(query);
  const newQuery = queryString.stringify({
    ...queryObj,
    skipCache: true,
  });
  const link = `${href}?${newQuery}`;

  const handleClick = () => {
    closeNotifications();
    updateVisitedStatus(notification, true);
  };

  const trackInteraction = (pageId: string) => {
    dive.trackUserInteract(pageId, router.asPath, 'notification_click', 'link');
  };

  const handleUserClick = () => {
    trackInteraction(linkTo.href);
  };

  const handleLinkToClick = () => {
    trackInteraction(linkTo.as);
  };

  return (
    <S.NotificationContainer onClick={handleClick}>
      {!visited && (
        <FlexBox
          position="absolute"
          right="12px"
          top="12px"
          width="10px"
          height="10px"
          backgroundColor={theme.colors.text.error}
          borderRadius="8px"
        />
      )}
      <S.Notification gap="1rem" padding="1rem 1.5rem 1rem 1rem" width="100%">
        {type !== notificationsTypesMap.FEATURED_CONTENT && linkTo.href && (
          <FlexBox>
            <Link href={linkTo.href} passHref onClick={handleUserClick}>
              <CircularImage src={pictureUrl} size={40} />
            </Link>
          </FlexBox>
        )}
        {type === notificationsTypesMap.FEATURED_CONTENT && (
          <Link href={link} passHref onClick={handleLinkToClick}>
            <S.Trophy>
              <Trophy size={32} color={theme.colors.text.error} />
            </S.Trophy>
          </Link>
        )}
        <Link href={link}>
          <FlexBox
            direction="column"
            onClick={handleLinkToClick}
            justifyContent="space-between"
            height="100%"
            gap="0.25rem">
            <Label size="medium" color={theme.colors.text.info}>
              <DangerousHtmlContent html={getNotificationText(notification)} />
            </Label>
            <FlexBox alignItems="center" gap="0.25rem">
              {Icon}
              <Label color={theme.colors.text.info} size="medium">
                <RelativeDate value={createdAt} />
              </Label>
            </FlexBox>
            {image && <S.Thumbnail style={{ backgroundImage: `url(${image.url})` }} />}
          </FlexBox>
        </Link>
      </S.Notification>
    </S.NotificationContainer>
  );
};
