import { Spacer } from 'components/layouts/Spacer';
import { Spinner } from 'components/common/spinners/Spinner';
import { EthereumLogo } from '../../../components/logos/Ethereum';
import * as S from './Button.styles';

export type ButtonProps = {
  onClick?: () => void;
  loading?: boolean;
};

export const Button = ({ onClick, loading }: ButtonProps) => (
  <S.ButtonWrapper onClick={onClick} style={{ cursor: loading ? 'not-allowed' : 'pointer' }}>
    <S.IconWrapper>
      <EthereumLogo size={22} />
    </S.IconWrapper>
    {loading ? <Spinner /> : <S.Title>Continue with wallet</S.Title>}
    <Spacer width={24} />
  </S.ButtonWrapper>
);
