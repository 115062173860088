import React, { FC } from 'react';
import * as S from './CenteredSPinner.styles';
import { CenteredSpinnerProps } from './CenteredSpinner.types';

export const CenteredSpinner: FC<CenteredSpinnerProps> = ({ size = 20, color = '#fff' }) => (
  <S.Container aria-label="loading...">
    <S.Rings size={size} color={color}>
      <div />
      <div />
      <div />
      <div />
    </S.Rings>
  </S.Container>
);
