import styled from 'styled-components';

export const ButtonWrapper = styled.div`
  background: ${({ theme }) => theme.primaryColor.white};
  cursor: pointer;
  padding: 0 8px;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  flex-direction: row;
  position: relative;
`;

export const IconWrapper = styled.div`
  margin-right: 4px;
  margin-top: 4px;
`;

export const Title = styled.span`
  color: ${({ theme }) => theme.primaryColor.appBlack};
  font-weight: 500;
  font-size: ${({ theme }) => theme.fontSize.menuNav};
`;
