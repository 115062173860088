import { WalletSignInButtonProps } from 'views/global/Wallet/WalletSignInButton';
import { Button } from 'views/global/Wallet/Button';
import dynamic from 'next/dynamic';

const WalletSignInButton = dynamic(() => import('views/global/Wallet/WalletSignInButton'), {
  ssr: false,
  loading: () => <Button loading />,
});

export const DynamicWalletSignInButton = ({ navigateToUsername, onCompleted }: WalletSignInButtonProps) => (
  <WalletSignInButton navigateToUsername={navigateToUsername} onCompleted={onCompleted} />
);
