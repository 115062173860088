import { Spinner } from 'components/common/spinners/Spinner';
import { ButtonHTMLAttributes, forwardRef } from 'react';
import theme from 'style/theme';
import styled from 'styled-components';

type Size = { padding: { default: string; withIcon: string }; borderRadius: string; height: string };
type Sizes = Record<keyof typeof theme.typography.button, Size>;

const sizes: Sizes = {
  large: {
    padding: {
      default: '20px 24px',
      withIcon: '20px 24px 20px 18px',
    },
    borderRadius: '4px',
    height: '56px',
  },
  medium: {
    padding: {
      default: '13px 16px',
      withIcon: '13px 16px 13px 12px',
    },
    borderRadius: '4px',
    height: '40px',
  },
  small: {
    padding: {
      default: '12px 10px',
      withIcon: '12px 10px 12px 8px',
    },
    borderRadius: '4px',
    height: '32px',
  },
};

export type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  variety: 'primary' | 'secondary' | 'text' | 'ghost';
  size: keyof typeof sizes;
  text?: string | number;
  Icon?: JSX.Element;
  fullWidth?: boolean;
  busy?: boolean;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  children?: React.ReactNode;
};

const StyledButton = styled.button<{
  size: keyof typeof sizes;
  variety: 'primary' | 'secondary' | 'text' | 'ghost';
  fullWidth?: boolean;
  hasIcon?: boolean;
}>`
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  transition: border ${({ theme }) => theme.transition.fast};
  transition: background-color ${({ theme }) => theme.transition.fast};
  gap: 4px;
  border: 1px solid ${({ theme, variety }) => theme.colors.button[variety].default.border};
  padding: ${({ size, hasIcon }) => sizes[size].padding[hasIcon ? 'withIcon' : 'default']};
  background-color: ${({ theme, variety }) => theme.colors.button[variety].default.background};
  color: ${({ theme, variety }) => theme.colors.button[variety].default.text};
  font-size: ${({ theme, size }) => theme.typography.button[size].size};
  font-style: ${({ theme, size }) => theme.typography.button[size].style};
  font-weight: ${({ theme, size }) => theme.typography.button[size].weight};
  line-height: ${({ theme, size }) => theme.typography.button[size].lineHeight};
  height: ${({ size }) => sizes[size].height};
  border-radius: ${({ size }) => sizes[size].borderRadius};
  white-space: nowrap;
  &:hover:not([disabled]) {
    background-color: ${({ theme, variety }) => theme.colors.button[variety].hover.background};
    color: ${({ theme, variety }) => theme.colors.button[variety].hover.text};
    border: 1px solid ${({ theme, variety }) => theme.colors.button[variety].hover.border};
    cursor: pointer;
  }

  &:disabled {
    background-color: ${({ theme, variety }) => theme.colors.button[variety].disabled.background};
    color: ${({ theme, variety }) => theme.colors.button[variety].disabled.text};
    border: 1px solid ${({ theme, variety }) => theme.colors.button[variety].disabled.border};
    cursor: not-allowed;
  }

  &:active:not([disabled]) {
    color: ${({ theme, variety }) => theme.colors.button[variety].active.text};
    border: 1px solid ${({ theme, variety }) => theme.colors.button[variety].active.border};
    background-color: ${({ theme, variety }) => theme.colors.button[variety].active.background};
  }
`;

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ size, variety, onClick, Icon, text, busy, disabled, children, ...props }, ref) => {
    return (
      <StyledButton
        onClick={onClick}
        ref={ref}
        role="button"
        disabled={disabled || busy}
        size={size}
        variety={variety}
        hasIcon={Boolean(Icon) && Boolean(text)}
        {...props}>
        {busy ? (
          <Spinner white />
        ) : (
          <>
            {Icon}
            {text}
            {children}
          </>
        )}
      </StyledButton>
    );
  }
);
