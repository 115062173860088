import { Theme } from '@hiberworld/theme';
import { CenteredSpinner } from 'components/common/spinners/CenteredSpinner';
import React, { ButtonHTMLAttributes, MouseEventHandler } from 'react';
import styled, { css } from 'styled-components';
import { FlexBox } from '../layouts/FlexBox';

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  text?: string;
  textStyle?: keyof Theme['typography']['button'];
  extraLine?: string;
  icon?: string;
  image?: string;
  socialID?: string;
  bgImage?: string;
  thumb?: string;
  width?: string;
  height?: string;
  className?: string;
  href?: string;
  busy?: boolean;
  target?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  children?: React.ReactNode;
  iconLeft?: JSX.Element;
  iconRight?: JSX.Element;
  alt?: string;
  as?: React.ElementType;
}

const ButtonWrapper = styled.button<{ width: string; height: string; textStyle?: ButtonProps['textStyle'] }>`
  vertical-align: middle;
  cursor: pointer;
  box-sizing: border-box;
  display: inline-block;
  position: relative;
  border: 0;
  height: ${props => (props.height ? props.height : 'auto')};
  width: ${props => (props.width ? props.width : 'auto')};
  text-decoration: none;
  overflow: hidden;
  display: inline-flex;
  justify-content: center;
  align-items: center;

  border-radius: 0.25rem;
  padding: 0.8rem 1.5rem;
  min-height: 33px;
  font-size: 0.9rem;
  background-color: #585858;
  background-position: center;
  background-size: cover;
  color: white;
  white-space: nowrap;

  &:link,
  &:visited {
    color: white;
  }
  &:hover {
    color: white;
    background-color: #585858;
    text-decoration: none;
  }
  &:active {
    transition: none;
    transform: scale(0.985);
  }

  .button-inner {
    i {
      margin-right: 0.3rem;
    }
  }

  &.alpha {
    color: #ffffff;
    background-color: transparent;
    &:hover {
      background-color: #292a31;
    }
    i {
      color: #24c790;
    }
  }

  &.green {
    background-color: #09b584;
    color: white;
    &:hover {
      background-color: #07986f;
    }
  }

  &.purple {
    background-color: #6424cc;
    color: white;
    &:hover {
      background-color: #501ba5;
    }
  }

  &.disabled {
    color: #d8d8d8;
    &:hover {
      cursor: default;
      color: #d8d8d8;
    }
  }

  &.image-button {
    background-color: #3d3d3d;
  }

  &.gray {
    color: #fff;
    background-color: #292a31;
    &:hover:not([disabled]) {
      background-color: #40424d;
    }
    i {
      color: #24c790;
    }
  }

  &.secondary {
    color: #fff;
    background-color: ${({ theme }) => theme.primaryColor.veryDark};

    &:hover:not([disabled]) {
      background-color: ${({ theme }) => theme.primaryColor.appDarkGray};
    }
  }

  &.red {
    background-color: #e81623;
    color: white;

    a {
      color: white;
    }
    &:hover {
      background-color: #b9121c;
    }
  }

  &.transparent {
    background-color: transparent;
    color: #e81623;
    &:hover {
      background-color: transparent;
      color: #b9121c;
    }
  }

  &.selected {
    background-color: #09b584;
    color: white;
    &:hover {
      background-color: #07986f;
    }
  }

  &:disabled {
    cursor: not-allowed;
    background-color: ${({ theme }) => theme.primaryColor.appDarkGray};
    color: ${({ theme }) => theme.primaryColor.textGray};
    &:hover {
      background-color: ${({ theme }) => theme.primaryColor.appDarkGray};
    }
  }

  &.small {
    width: 1.25rem;
    height: 1.25rem;
    min-height: 1.25rem;
    background-color: transparent;
    &:hover {
      color: #585858;
      background-color: #b4b4b4;
    }
  }

  &.center {
    align-self: center;
  }

  &.bigger {
    font-size: 1.3rem;
    padding: 0.7rem 2.6rem;
    @media (max-width: 650px) {
      font-size: 1.3rem;
      padding: 0.9rem 1.2rem;
      width: 100%;
    }
  }

  &.smaller {
    font-size: 0.9rem;
    padding: 0 1rem;
  }

  &.fullsize {
    width: 100%;
  }

  &.fullsize-mobile {
    @media (max-width: ${({ theme }) => theme.breakpoint.mediumSmall}) {
      width: 100%;
    }
  }

  &.margin-bottom {
    margin-bottom: 0.5rem;
  }

  &.margin-right {
    margin-right: 1rem;
  }

  &.ghost {
    background-color: transparent;

    &:hover:not([disabled]) {
      background-color: #292a31;
    }
  }

  @media (max-width: 900px) {
    &.collapse {
      padding: 0px 0.7rem;
      span {
        display: none;
      }
      i {
        margin-right: 0 !important;
      }
    }
  }

  transition: all 0.25s ease-out;

  ${({ textStyle, theme }) => {
    if (textStyle) {
      return css`
        font-size: ${theme.typography.button[textStyle].size};
        font-style: ${theme.typography.button[textStyle].style};
        font-weight: ${theme.typography.button[textStyle].weight};
        line-height: ${theme.typography.button[textStyle].lineHeight};
        font-family: ${theme.typography.button[textStyle].font.web};
      `;
    }
  }}
`;

const ExtraLine = styled.div`
  font-size: 0.75rem;
`;

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      text,
      textStyle,
      extraLine,
      iconLeft: IconLeft,
      iconRight: IconRight,
      bgImage,
      className,
      onClick,
      width,
      busy,
      children,
      ...rest
    },
    ref
  ) => (
    <ButtonWrapper
      ref={ref}
      className={`button ${className || ''}`}
      style={{ backgroundImage: bgImage && `url(${bgImage})` }}
      width={width}
      onClick={onClick}
      textStyle={textStyle}
      {...rest}>
      <FlexBox opacity={`${busy ? 0 : 1}`} direction="column" gap="0.25rem">
        <FlexBox alignItems="center" gap="0.25rem" justifyContent="center">
          {IconLeft}
          {Boolean(text) && <span>{text}</span>}
          {IconRight}
        </FlexBox>
        {extraLine && <ExtraLine>{extraLine}</ExtraLine>}
      </FlexBox>
      {busy && <CenteredSpinner />}
      {children}
    </ButtonWrapper>
  )
);

Button.displayName = 'Button';

export default Button;
