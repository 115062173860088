import { Close, Menu } from '@hiberworld/icons';
import styled from 'styled-components';

export const HamburgerWrapper = styled.div`
  position: relative;
  cursor: pointer;
  display: flex;
`;

export const OpenMenu = styled(Menu)`
  transition: ${({ theme }) => theme.transition.fast};
  color: ${({ theme }) => theme.primaryColor.appLightGray};
  &:hover {
    color: ${({ theme }) => theme.primaryColor.white};
  }
`;

export const CloseMenu = styled(Close)`
  color: ${({ theme }) => theme.primaryColor.white};
`;
