import styled from 'styled-components';
import { HamburgerMenuStyleProps, UsernameStyleProps } from './HamburgerMenu.types';
import { Box } from 'components/layouts/Box/Box';
import { FlexBox } from 'components/layouts/FlexBox';
import { TOPBAR_HEIGHT } from 'views/global/TopBar/TopBar.styles';
import Link from 'next/link';

export const HamburgerMenu = styled.div<HamburgerMenuStyleProps>`
  display: block;
  position: absolute;
  width: 100%;
  max-width: 320px;
  max-height: 85vh;
  top: calc(${TOPBAR_HEIGHT}px + 1rem);
  left: 100%;
  padding-top: 1.75rem;
  translate: ${({ open }) => (open ? 'calc(-100% - 32px)' : '0')} 0;
  background-color: ${({ theme }) => theme.colors.navigation.background};
  transition: all ${({ theme }) => theme.transition.fast} ease-in-out;
  overflow-y: auto;
  box-shadow: ${({ theme }) => theme.boxShadow.menu};
  border-radius: ${({ theme }) => theme.borderRadius.large};
  user-select: none;

  @media (max-width: ${({ theme }) => theme.breakpoint.mediumSmall}) {
    top: ${TOPBAR_HEIGHT}px;
    border-radius: 0;
    max-width: 100%;
    max-height: calc(100dvh - ${TOPBAR_HEIGHT}px);
    translate: ${({ open }) => (open ? '-100%' : '0')} 0;
  }

  @media (max-height: ${({ theme }) => theme.breakpoint.small}) and (orientation: landscape) {
    padding: ${({ theme }) => `${theme.padding.small} 0`};
  }
`;

export const UserInfo = styled(Link)`
  display: block;
  padding: 0 1.75rem;
  transition: all 0.2s ease-out;

  &:hover {
    filter: saturate(150%) brightness(120%);
  }
`;

export const XpBar = styled.div`
  background: ${({ theme }) => theme.primaryColor.white};
  border-radius: ${({ theme }) => theme.borderRadius.large};
  width: 100%;
  height: 9px;
  display: flex;
`;

export const Username = styled(FlexBox)<UsernameStyleProps>`
  flex-direction: column;
  margin-left: 1rem;
  max-width: ${({ nameWidth }) => (nameWidth > 10 ? '200px' : 'inherit')};
`;

export const ProfileContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  cursor: pointer;
  p {
    margin: 0;
  }
  &:hover {
    cursor: pointer;
  }
`;

export const AdminPanel = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  padding: 0.5rem 0.25rem;
  background-color: ${({ theme }) => theme.primaryColor.appDarkGray};
`;

export const CloseIcon = styled.div`
  padding: 0.5rem 0.25rem;
  &:hover {
    color: ${({ theme }) => theme.primaryColor.red};
    cursor: pointer;
  }
`;

export const SmallGrid = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  text-align: left;
  padding: 0 1.75rem;
  gap: 0.25rem;
`;

export const Line = styled.div`
  height: 1px;
  margin: 0 1.75rem;
  display: flex;
  background-color: ${({ theme }) => theme.primaryColor.appDarkGray};
`;
