import { FlexBox } from 'components/layouts/FlexBox';
import { useAuthModal } from 'components/legacy/modals/AuthModal/useAuthModal';
import { useMe } from 'hooks/useMe';
import * as S from './LogInOrRegister.styles';
import { useHideMobileLogInOrRegister } from './logInOrRegister.utils';
import { useDive } from 'hooks/useDive';

export const LogInOrRegister = () => {
  const { isLoggedIn } = useMe();
  const authModal = useAuthModal();
  const hideMobileLogin = useHideMobileLogInOrRegister();
  const dive = useDive();

  if (hideMobileLogin || isLoggedIn) {
    return null;
  }

  const signup = () => {
    dive.trackModalOpen({
      description: `Sign up for HiberWorld`,
      name: 'sign_up',
      origin: 'login_or_register_footer',
    });
    authModal.open('SIGN_UP');
  };

  const login = () => {
    dive.trackModalOpen({
      description: `Log in to HiberWorld`,
      name: 'login',
      origin: 'login_or_register_footer',
    });
    authModal.open('LOGIN');
  };

  return (
    <S.Wrapper className="loginOrRegisterContainer">
      <FlexBox justifyContent="center" alignItems="center" flex={1}>
        <S.Arrow />
        <S.Text role="button" onKeyDown={login} onClick={login}>
          Log in
        </S.Text>

        <span>or</span>
        <S.Text role="button" onKeyDown={signup} onClick={signup}>
          create a new account
        </S.Text>
      </FlexBox>
    </S.Wrapper>
  );
};
