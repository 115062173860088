import { Arrow as ArrowIcon } from '@hiberworld/icons';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;

  text-align: center;
  position: relative;
  z-index: 10;
  background: ${({ theme }) => theme.primaryColor.purple};
  width: calc(100% - 20px);
  position: fixed;
  bottom: 0;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  overflow: hidden;
  left: 10px;
  color: #b7afc3;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);

  i {
    color: #b7afc3;
    margin-right: 0.25rem;
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.medium}) {
    display: none;
  }
`;

export const Text = styled.span`
  padding: 0.75rem 0.25rem;
  color: ${({ theme }) => theme.primaryColor.white};
`;

export const Arrow = styled(ArrowIcon)`
  transform: rotate(180deg);
`;
