import { useMemo } from 'react';
import { useRandomizedUsernamesQuery } from 'generated/graphql';

export const useRandomizedUsernames = () => {
  const { data, loading, refetch } = useRandomizedUsernamesQuery({ variables: { amount: 30 } });
  const usernames = useMemo(() => data?.randomizedUsernames || [], [data?.randomizedUsernames]);
  return {
    usernames,
    loading,
    refetch,
  };
};
