import { CircularImage } from 'components/common/images/CircularImage';
import { FlexBox } from 'components/layouts/FlexBox';
import { Heading, Label } from 'components/typography';
import { Box } from 'components/layouts/Box/Box';
import { Link } from 'components/common/Link';
import styled, { useTheme } from 'styled-components';

export type UserInfoProps = {
  level: number;
  username: string;
  pictureUrl: string;
  progressToNextLevel: number;
};

const Circle = styled.circle<{ offset: number; circumference: number }>`
  animation: slideIn 2s ease-out;
  animation-fill-mode: forwards;

  @keyframes slideIn {
    0% {
      stroke-dashoffset: ${({ circumference }) => circumference};
    }
    100% {
      stroke-dashoffset: ${({ offset }) => offset};
    }
  }
`;

const HideOnMobile = styled(Box)`
  @media (max-width: ${({ theme }) => theme.breakpoint.breakMobile}) {
    display: none;
  }

  transition: all 0.2s ease-out;

  &:hover {
    filter: saturate(150%) brightness(120%);
  }
`;

const CircluarProgressBar = ({
  progress,
  size,
  pictureUrl,
}: {
  progress: number;
  size: number;
  pictureUrl: string;
}) => {
  const theme = useTheme();
  const strokeWidth = 3;
  const radius = size / 2 - strokeWidth;
  const circumference = 2 * Math.PI * radius;
  const offset = circumference * ((100 - progress) / 100);
  const imageMargin = 3;
  const imageSize = size - strokeWidth * 2 - imageMargin * 2;

  return (
    <FlexBox position="relative" alignItems="center" justifyContent="center">
      <svg width={size} height={size} style={{ transform: 'rotate(-90deg)', display: 'block' }}>
        <linearGradient id="linear" x1="100%" y1="0%" x2="100%" y2="100%">
          <stop offset="0%" stopColor="#BD3FD1" />
          <stop offset="100%" stopColor="#814CC7" />
        </linearGradient>
        <circle
          cx="50%"
          cy="50%"
          r={radius}
          strokeWidth={strokeWidth}
          fill="none"
          stroke={theme.colorTheme.greyscale.dark}
        />
        <Circle
          cx="50%"
          cy="50%"
          r={radius}
          strokeWidth={strokeWidth}
          fill="none"
          stroke="url(#linear)"
          strokeLinecap="round"
          offset={offset}
          circumference={circumference}
          strokeDasharray={circumference}
        />
      </svg>
      <Box position="absolute">
        <CircularImage src={pictureUrl} size={imageSize} alt="See your progress" />
      </Box>
    </FlexBox>
  );
};

export const UserInfo = ({ pictureUrl, username, level, progressToNextLevel }: UserInfoProps) => {
  const theme = useTheme();
  return (
    <HideOnMobile>
      <Link href={`/user/${username}/achievements`}>
        <FlexBox direction="row" gap="0.5rem" alignItems="center" data-testid="reward-link">
          <CircluarProgressBar progress={progressToNextLevel * 100} size={40} pictureUrl={pictureUrl} />
          <FlexBox direction="column" gap="0.11rem">
            <Heading size="h6">{username}</Heading>
            <Label size="specialSmall" color={theme.colors.text.info}>
              Level {level}
            </Label>
          </FlexBox>
        </FlexBox>
      </Link>
    </HideOnMobile>
  );
};
