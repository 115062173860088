import { ApolloClient, ApolloProvider, NormalizedCacheObject } from '@apollo/client';
import { getDataFromTree } from '@apollo/client/react/ssr';
import { config } from 'config';
import TopBarContainer from 'containers/TopBarContainer';
import { CookieProvider } from 'context/cookies/cookies.provider';
import { MeProvider } from 'hooks/useMe';
import { refreshAccessTokenIfExpired, setJwtCookie } from 'lib/auth';
import { getApolloClient } from 'lib/getApolloClient';
import { globals } from 'lib/globals';
import 'lib/initDive';
import { parseCookies } from 'lib/parseCookies';
import { LastEventProvider } from 'lib/useLastEvent';
import { ModalProvider } from 'lib/useModal';
import App from 'next/app';
import dynamic from 'next/dynamic';
import { Inter } from 'next/font/google';
import router from 'next/router';
import GlobalStyles from 'style/globalStyles';
import theme from 'style/theme';
import { ThemeProvider } from 'styled-components';
import { CustomAppContext } from 'types/page.types';
import { isClientSide } from 'utils/isClientSide';

const inter = Inter({
  subsets: ['latin'],
  display: 'swap',
  preload: true,
  weight: ['400', '500', '600', '700', '800', '900'],
});

const LazyInitDataDog = dynamic(() => import('errorTracking/datadog'), {
  ssr: false,
});

const ChatWrapper = dynamic(() => import('views/global/ChatWindow/ChatWrapper').then(module => module.ChatWrapper), {
  ssr: false,
});

const FontImport = () => {
  return (
    <style jsx global>{`
      * {
        font-family: ${inter.style.fontFamily};
      }
    `}</style>
  );
};

const createTree = (Comp, apollo, cookies, pageProps) => {
  return (
    <ApolloProvider client={apollo}>
      <MeProvider>
        <CookieProvider cookies={cookies}>
          <LastEventProvider>
            <FontImport />
            <ThemeProvider theme={theme}>
              <GlobalStyles />
              <ModalProvider>
                <TopBarContainer hideNavigation={pageProps.hideNavigation} />
                <Comp {...pageProps} />
                <ChatWrapper />
                <LazyInitDataDog />
              </ModalProvider>
            </ThemeProvider>
          </LastEventProvider>
        </CookieProvider>
      </MeProvider>
    </ApolloProvider>
  );
};

interface AppPops {
  Component: () => JSX.Element;
  jwt?: string;
  stateApollo: NormalizedCacheObject;
  cookies: string;
  apolloClient?: ApolloClient<NormalizedCacheObject>;
  pageProps: Record<string, unknown>;
}

const MyApp = (props: AppPops) => {
  const { Component, jwt, stateApollo, cookies, pageProps, apolloClient } = props;
  // On token swap (i.e. jwt provided) -> update cookies and refresh page
  if (jwt && typeof window !== 'undefined') {
    // Set cookie
    setJwtCookie(jwt);
    // Reload application (to ensure all downstream services receive updated token)
    router.reload();
  }

  const apollo =
    apolloClient ??
    getApolloClient({
      initialState: stateApollo,
      options: {
        getCookies: () => parseCookies(),
      },
    });

  return createTree(Component, apollo, cookies, pageProps);
};

MyApp.getInitialProps = async (appContext: CustomAppContext) => {
  const { AppTree, ctx } = appContext;
  const pageProps = await App.getInitialProps(appContext);
  const now = Date.now();

  if (!isClientSide()) {
    const cookies = parseCookies(ctx);
    if (!cookies.token) {
      appContext.ctx.res?.setHeader('Cache-Control', 'max-age=600');
    }
  }

  globals.serverTime = now;

  let apollo = getApolloClient({
    initialState: {},
    options: {
      getCookies: () => parseCookies(ctx),
    },
  });

  const cookies = ctx.req ? ctx.req.headers.cookie || '' : (isClientSide() && document.cookie) || '';

  let stateApollo = {};

  if (!isClientSide()) {
    const parsedCookies = parseCookies(ctx);

    if (parsedCookies.token) {
      const newAccessToken = await refreshAccessTokenIfExpired(parsedCookies);

      if (parsedCookies.token !== newAccessToken) {
        const now = new Date();
        now.setMonth(now.getMonth() + 1);

        // update the `token` cookie so that the client doesn't have to refresh the access token again before it makes a request
        ctx.res?.setHeader(
          'Set-Cookie',
          `token=${newAccessToken}; Path=/; Expires=${now.toUTCString()}; Domain=${
            config.environment === 'development' ? 'localhost' : `.${config.domain}`
          }; Secure`
        );
        // re-create the apollo client with the new token in the `getCookies()` return value
        // if we don't do this the token used by apollo will be the old access token
        apollo = getApolloClient({
          initialState: {},
          options: {
            getCookies: () => ({
              ...parseCookies(ctx),
              token: newAccessToken ?? '',
            }),
          },
        });
      }
    }

    try {
      // Run all GraphQL queries
      await getDataFromTree(<AppTree {...pageProps} apolloClient={apollo} />);
    } catch (error) {
      console.error('Failed to execute server side query', error);
    }

    // Extract query data from the Apollo store
    stateApollo = apollo.cache.extract();
  }

  return { ...pageProps, stateApollo, cookies };
};

export default MyApp;
