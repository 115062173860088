import { Theme } from '@hiberworld/theme';
import styled from 'styled-components';

export const ButtonText = styled.span<{ size: keyof Theme['typography']['button']; color?: string; maxWidth?: string }>`
  /* font-family: ${({ theme, size }) => theme.typography.button[size].font.web}; */
  font-weight: ${({ theme, size }) => theme.typography.button[size].weight};
  font-size: ${({ theme, size }) => theme.typography.button[size].size};
  line-height: ${({ theme, size }) => theme.typography.button[size].lineHeight};
  font-style: ${({ theme, size }) => theme.typography.button[size].style};
  margin: 0;
  color: ${({ color, theme }) => color ?? theme.primaryColor.white};
  max-width: ${({ maxWidth }) => maxWidth ?? 'none'};
`;
