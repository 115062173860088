/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react';
import { FieldError, FieldValues, Path, useForm, UseFormProps } from 'react-hook-form';
import { FetchResult } from '@apollo/client';
import { useDive } from '../hooks/useDive';

type Queries<T> = Promise<FetchResult<T, Record<string, any>, Record<string, any>>>[];

type ErrorMessage = {
  id: string;
  defaultMessage: string;
};

type GQLError = {
  message: string;
  fields: {
    [key: string]: ErrorMessage[];
  };
  extensions: {
    fields: {
      [key: string]: ErrorMessage[];
    };
  };
};
type FormSubmitResults<T> = {
  success: boolean;
  data: T[];
  errors: GQLError[];
};

export const useSubmitForm = <FormData extends FieldValues>(options: UseFormProps<FormData>) => {
  const form = useForm(options);

  const dive = useDive();
  const [loading, setLoading] = useState(false);

  const hasFormErrors = () => {
    return Object.keys(form.formState.errors).length !== 0;
  };

  const canSubmit = () => {
    return form.formState.isDirty || form.formState.isValid;
  };

  const handleError = e => {
    Object.entries(e).forEach(([, values]) => {
      dive.trackErrorCreatedCustom('', '', (values as FieldError)?.message ?? '', '');
    });
  };

  const handleSubmit = handler => {
    return form.handleSubmit((...values) => {
      if (!hasFormErrors() && canSubmit()) {
        handler(...values);
      }
    }, handleError);
  };

  async function submitGqlForm<T, U>(queries: Queries<T>): Promise<FormSubmitResults<U>> {
    setLoading(true);

    let results: FormSubmitResults<U>;

    try {
      const res = await Promise.all(queries);

      const errors: GQLError[] = [];

      res.forEach(r => {
        if (r?.errors) {
          errors.push(...(r.errors as any));
        }
      });

      errors.forEach(error => {
        const fields = error?.fields || error?.extensions?.fields;

        if (fields) {
          Object.entries(fields).forEach(([key, values]) => {
            if (key === '_error') {
              return;
            }

            values.forEach(value => {
              form.setError(key as Path<FormData>, { message: value.defaultMessage });
              dive.trackErrorCreatedCustom(value.id, '', value.defaultMessage, '');
            });
          });
        }
        // Here is where a general error message will appear from the api. But we don't handle that yet.
      });

      results = {
        data: res as U[],
        success: errors.length === 0,
        errors,
      };
    } catch (error) {
      results = {
        data: [],
        success: false,
        errors: [],
      };
    } finally {
      setLoading(false);
    }

    return results;
  }

  return {
    submitGqlForm,
    handleSubmit,
    loading,
    form,
  };
};
