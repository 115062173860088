import { useCallback } from 'react';
import { useFindAllFeatureTogglesQuery, useFindFeatureToggleQuery } from 'generated/graphql';
import { ValueOf } from 'types/types.helpers';

type FeatureToogleModule = typeof import('constants/featureToggle.constants');
export type FeatureToggleType = ValueOf<FeatureToogleModule>;

export const useFeatureToggles = () => {
  const { data, loading, refetch } = useFindAllFeatureTogglesQuery();

  const featureToggles = [...(data?.findAllFeatureToggles ?? [])].sort((a, b) =>
    a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1
  );

  const isFeatureEnabled = useCallback(
    (name: string) => !!featureToggles?.find(feature => feature.name === name)?.isEnabled,
    [featureToggles]
  );

  return { featureToggles, isFeatureEnabled, loading, refetch };
};

export const useFeatureToggle = (name: FeatureToggleType) => {
  const { data, loading, refetch } = useFindFeatureToggleQuery({ variables: { name } });
  const feature = data?.findFeatureToggle;

  const isSubFeatureOn = (subFeatureName: string) => {
    if (!feature || !feature.isEnabled || !feature?.values || feature.values.length < 1) {
      return false;
    }

    return feature.values.some(sub => sub.name === subFeatureName && sub.isEnabled);
  };

  return { loading, refetch, isSubFeatureOn, ...feature };
};
