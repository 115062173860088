interface DangerousHtmlContentProps {
  html?: string | null;
}
const DangerousHtmlContent = ({ html }: DangerousHtmlContentProps) => {
  if (!html) {
    return null;
  }

  // eslint-disable-next-line react/no-danger
  return <div dangerouslySetInnerHTML={{ __html: html }} />;
};

export default DangerousHtmlContent;
