import styled, { keyframes } from 'styled-components';
import { CenteredSpinnerProps } from './CenteredSpinner.types';

export const Container = styled.div`
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  align-items: center;
  justify-content: center;
`;

const animation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const Rings = styled.div<CenteredSpinnerProps>`
  position: relative;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;

  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    animation: ${animation} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border: 3px solid;
    border-color: ${({ color }) => color} transparent transparent transparent;

    :nth-child(1) {
      animation-delay: -0.45s;
    }

    :nth-child(2) {
      animation-delay: -0.3s;
    }

    :nth-child(3) {
      animation-delay: -0.15s;
    }
  }
`;
