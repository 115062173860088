export const phrases = {
  birthdayEmpty: 'Please fill out your birthday',
  birthdayInvalid: 'Please enter a valid date',
  commentTooLong: 'Comment too long. Only 256 characters allowed',
  commentTooShort: 'Comment too short. Minimum 1 characters',
  dmNoConversations: 'Search for friends to start a conversation!',
  dmNoSearchResults: 'No results found',
  dmNoFriends: 'Make friends to start chatting and explore HiberWorld together!',
  dnConnectionLost: 'Connection lost',
  dmSearchPlaceholder: 'Search friends',
  emailInvalid: 'Please enter a valid email address',
  emptyField: "This field can't be empty",
  fieldIsRequired: 'This field is required',
  missingFields: 'Ops! Some of the fields needs attention, please have another look',
  passwordEmpty: 'Please enter your password',
  passwordChooseEmpty: 'Passwords must be at least 4 characters long',
  passwordTooShort: 'Passwords must be at least 4 characters long',
  passwordTooLong: 'Passwords can have up to 256 characters',
  passwordsDontMatch: "The passwords don't match",
  passwordIncorrect: 'Your old password is incorrect',
  projectNameMatchFailed: 'You must enter the name of the project',
  projectNameInvalid: "Title can only contain letters, number, emojis, spaces and the characters: _ - , . ( ) | [ ] /'",
  projectNameTooShort: 'Title must be at least 4 characters long',
  projectDescriptionTooLong: 'Description too long. Only 750 characters allowed',
  projectEditSaved: 'Project settings saved',
  projectTooManyTags: 'You can only use five tags',
  searchQueryTooShort: 'Please enter at least 3 characters to search',
  settingsSaved: 'Settings successfully saved',
  usernameChooseEmpty: 'Usernames must be between 3 and 18 characters long',
  usernameEmailEmpty: 'Please enter your username or email address',
  usernameInvalid: 'Username can only contain letters, number and the character: _',
  usernameOrPasswordInvalid: 'Incorrect account or password',
  usernameOrEmailEmpty: 'Please enter your username or email address',
  usernameWrongLength: 'Usernames must be between 3 and 18 characters long',
  usernameAlreadyTaken: 'That username is taken. Try another.',
  verifyAge: 'Please verify your age to update your email',
  dayEmpty: 'Enter date',
  monthEmpty: 'Enter month',
  yearEmpty: 'Enter year',
  resetPasswordDisclaimer:
    "We can only send you a reset password email if you've added an email to your account settings.",
  youtubeInvalid: 'Please enter a valid YouTube channel url',
  instagramTooLong: 'Username must be less than 30 characters',
  instagramInvalid: 'Username can only include letters, numbers, underscores and periods',
  tagsTooMany: "You can't have more than 5 tags",
  tagTooLong: "A tag can't be longer than 30 characters",
  tagInvalid: 'A tag can only contain letters and numbers and no disallowed words',
  twitterTooLong: 'Username must be less than 15 characters',
  twitterInvalid: 'Username can only include letters, numbers and underscores',
  twitchWrongLength: 'Usernames can be 4 - 25 characters long',
  twitchInvalid: 'Username can only include letters, numbers and underscores',
  userDescriptionIllegalCharacters:
    'Description can only contain letters, number, emojis and the characters: -_.,\'"&*!:',
  userDescriptionTooLong: 'Description must be shorter than or equal to 256 characters',
  easy: 'easy',
  medium: 'medium',
  hard: 'hard',
  insane: 'insane',
  nameEmpty: 'Please enter a name',
  deviceEmpty: 'Please select a device type',
  contactDescriptionOutOfBounds: 'Description has to be between 15 and 1024 characters long',
  subjectEmpty: 'Please select a subject',
  notAcceptedConsent: 'Please accept the consent in order to contact support',
  tiktokInvalid: 'Username can only include letters, numbers, underscores and periods, and must not end with a period',
  tiktokWrongLength: 'Usernames can be 4 - 24 characters long',
  underAgeText:
    'To join Hiber you need to be 5 or older. But thanks for checking us out, we hope to see you again when you are old enough!',
  userNotValidating: 'Something went wrong when trying to change username, please contact support for help.',
  removeScheduledGame: 'Are you sure you want to remove this scheduled feature?',
  unableToSendEmailVerification:
    'We could not find a email associated with your account. Please reach out to us for further help.',
  emailVerificationSent:
    'It could take a moment for the email to arrive. If you do not receive any email, please check your email trash folder.',
  funTogether: `It's more fun together!`,
  signUpChat: 'Sign up to make friends on HiberWorld and start chatting!',
  signupForFree: 'SIGN UP FOR FREE',
  alreadyHaveAccount: 'Already have an account? ',
  logIn: 'Log In',
  avatarMenu: {
    title: 'My Avatar',
    changeOutfit: 'Change outfit',
    createAvatar: 'Create Avatar',
    viewClassic: 'View classic avatars',
  },
  rpm: {
    createAvatar: 'Create your own avatar to show off your style',
  },
  achievements: {
    wrongAvatarEquipped: 'Switch to a Ready Player Me avatar to equip this.',
    assetError: 'Opps! something went wrong, please try again.',
  },
};
