import { AuthModalProps } from './AuthModal.types';
import { ForgotPasswordModal } from './ForgotPasswordModal';
import { LoginModal } from './LoginModal';
import { SignUpModal } from './SignUpModal';
import { SignUpWithEmailModal } from './SignUpWithEmailModal';
import { UsernameModal } from './UsernameModal';

export const DEFAULT_LOGIN_HEADLINE = `Log in to HiberWorld`;
export const DEFAULT_SIGN_UP_HEADLINE = `Sign up for HiberWorld`;

export const AuthModal = ({ type, navigate, onClose, options }: AuthModalProps) => {
  if (type === 'USERNAME') {
    return (
      <UsernameModal
        onClose={onClose}
        navigateToSignUp={() => navigate('SIGN_UP')}
        navigateToLogin={() => navigate('LOGIN')}
        onSignUpCompleted={options?.onSignUpCompleted}
      />
    );
  }
  if (type === 'LOGIN') {
    return (
      <LoginModal
        navigateToForgotPassword={() => navigate('FORGOT_PASSWORD')}
        navigateToSignUp={() => navigate('SIGN_UP')}
        navigateToUsername={() => navigate('USERNAME')}
        onLoginCompleted={options?.onLoginCompleted}
        onClose={onClose}
        title={options?.loginTitle}
      />
    );
  }
  if (type === 'FORGOT_PASSWORD') {
    return <ForgotPasswordModal onClose={onClose} navigateToLogin={() => navigate('LOGIN')} />;
  }
  if (type === 'SIGN_UP_EMAIL') {
    return (
      <SignUpWithEmailModal
        handleBack={() => navigate('SIGN_UP')}
        navigateToUsername={() => navigate('USERNAME')}
        navigateToLogin={() => navigate('LOGIN')}
        onClose={onClose}
        hideLoginOption={options?.hideLoginOption}
        onSignUpCompleted={options?.onSignUpCompleted}
        keepOpenUntilNavigation={options?.keepOpenUntilNavigation}
      />
    );
  }
  if (type === 'SIGN_UP') {
    return (
      <SignUpModal
        onClose={onClose}
        navigateToLogin={() => navigate('LOGIN')}
        navigateToSignupWithEmail={() => navigate('SIGN_UP_EMAIL')}
        navigateToUsername={() => navigate('USERNAME')}
        hideLoginOption={options?.hideLoginOption}
        onSignUpCompleted={options?.onSignUpCompleted}
        title={options?.signUpTitle}
      />
    );
  }

  return null;
};
