import isNil from 'lodash/isNil';
import React, { forwardRef } from 'react';
import { useTheme } from 'styled-components';
import * as S from './SearchField.styles';
import { TextInputProps } from './SearchInput.types';
import { Spinner } from 'components/common/spinners/Spinner';
import { Close } from '@hiberworld/icons';

export const SearchField = forwardRef<HTMLInputElement, TextInputProps>(
  (
    {
      Icon,
      placeholder,
      value = '',
      setValue,
      backgroundColor,
      onEnter,
      onFocus,
      autoFocus,
      limit,
      borderColor,
      borderRadius,
      onClear,
      loading = false,
    },
    ref
  ) => {
    const theme = useTheme();
    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        onEnter?.(value);
      }
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!isNil(limit) && e.target.value.length > limit) {
        return;
      }

      setValue?.(e.target.value);
    };

    const clearSearch = () => {
      setValue?.('');
      onClear?.();
    };

    const hasValue = value.trim() !== '';

    return (
      <S.TextInputContainer>
        <S.IconWrapper>{Icon}</S.IconWrapper>

        <S.TextInput
          placeholder={placeholder}
          backgroundColor={backgroundColor || theme.colorTheme.greyscale.almostBlack}
          borderColor={borderColor}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          hasIcon={!!Icon}
          onFocus={onFocus}
          autoFocus={autoFocus}
          borderRadius={borderRadius}
          data-testid="TextInput"
          value={value}
          ref={ref}
          aria-label="Search"
        />
        {hasValue && loading && (
          <S.RightIcon>
            <Spinner tiny white />
          </S.RightIcon>
        )}
        {hasValue && !loading && (
          <S.RightIcon onClick={clearSearch}>
            <Close data-cy="ClearSearchField" data-testid="ClearSearchField" />
          </S.RightIcon>
        )}
      </S.TextInputContainer>
    );
  }
);

SearchField.displayName = 'TextInput';
