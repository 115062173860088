import { isClientSide } from 'utils/isClientSide';
import { DiveModalNames, DiveModalOrigins } from './useDive/types/DiveEvents';
import { useDive } from './useDive';

type DialogOpenOptions = {
  onOpen?: () => void;
  name: DiveModalNames;
  description: string;
  origin: DiveModalOrigins;
};
type DialogCloseOptions = {
  onClose?: () => void;
};

export const waitForAnimationsToComplete = async (element: Element) => {
  return Promise.allSettled(element.getAnimations().map(animation => animation.finished));
};

export const useDialog = () => {
  const dive = useDive();

  const open = async (dialogId: string, options: DialogOpenOptions) => {
    if (!isClientSide()) {
      throw new Error('Dialog can only be opened on client side');
    }

    const dialogElement: HTMLDialogElement = window[dialogId];

    if (!dialogElement) {
      throw new Error(`Dialog ${dialogId} not found`);
    }

    dive.trackModalOpen({ name: options.name, description: options.description, origin: options.origin });
    options?.onOpen?.();
    await waitForAnimationsToComplete(dialogElement);
    dialogElement.removeAttribute('inert');
    dialogElement.showModal();
  };

  const close = (dialogId: string, options?: DialogCloseOptions) => {
    if (!isClientSide()) {
      throw new Error('Dialog can only be opened on client side');
    }
    const dialogElement: HTMLDialogElement = window[dialogId];

    if (!dialogElement) {
      throw new Error(`Dialog ${dialogId} not found`);
    }
    options?.onClose?.();
    dialogElement.setAttribute('inert', '');
    dialogElement.close();
  };

  return { open, close };
};
