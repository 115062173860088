import { FlexBox } from 'components/layouts/FlexBox';
import React, { SelectHTMLAttributes, forwardRef } from 'react';
import { useTheme } from 'styled-components';
import * as S from './Select.styles';

type SelectProps = SelectHTMLAttributes<HTMLSelectElement> & { children?: React.ReactNode };

export const Select = forwardRef<HTMLSelectElement, SelectProps>(
  ({ children, placeholder, defaultValue, ...props }, ref) => {
    const theme = useTheme();
    return (
      <FlexBox position="relative" width="100%" alignItems="center">
        <S.HiberSelect ref={ref} {...props} defaultValue={defaultValue ?? ''}>
          {placeholder && (
            <option value="" disabled hidden>
              {placeholder}
            </option>
          )}
          {children}
        </S.HiberSelect>
        <S.DownIcon color={theme.colors.text.default} />
      </FlexBox>
    );
  }
);
