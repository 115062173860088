import styled from 'styled-components';

export const ErrorMessage = styled.p`
  color: ${({ theme }) => theme.primaryColor.red};
  ::first-letter {
    text-transform: capitalize;
  }
  font-weight: bold;
  max-width: 320px;
`;

export const Container = styled.button`
  width: 100%;
  display: flex;
  height: 40px;
  justify-content: space-between;
  align-items: center;
  font-size: ${({ theme }) => theme.typography.button.medium.size};
  font-weight: ${({ theme }) => theme.typography.button.medium.weight};
  line-height: ${({ theme }) => theme.typography.button.medium.lineHeight};

  padding: 0;
  background: ${({ theme }) => theme.primaryColor.white};
  border-radius: 4px;
  color: ${({ theme }) => theme.primaryColor.black};
  padding-left: 0.75rem;
  cursor: pointer;
  border: none;
  &:disabled {
    cursor: not-allowed;
    color: ${({ theme }) => theme.primaryColor.appDarkGrayLighter};
  }
`;

export const IconWrapper = styled.div`
  position: absolute;
  left: 10px;
  height: 20px;
  width: 20px;
`;

export const Title = styled.span`
  color: ${({ theme }) => theme.colorTheme.greyscale.hiberBlack};
`;
