export const FEATURE_TOGGLE_DAO_UNLIMITED_PLAYERS_IN_ROOM = 'daoUnlimitedPlayersInRoom' as const;
export const FEATURE_TOGGLE_SHOW_OLD_ENGINE_FEEDS_ON_START_PAGE = 'showOldEngineFeedsOnStartPage' as const;
export const FEATURE_TOGGLE_ENABLE_WALLET_AUTHENTICATION = 'enableWalletAuthentication' as const;
export const FEATURE_TOGGLE_EXTERNAL_DEVELOPER_SIGNUP = 'allowExternalDeveloperSignup' as const;
export const FEATURE_TOGGLE_SHOW_EDIT_WORLD_BUTTONS = 'showEditWorldButtons' as const;
export const FEATURE_TOGGLE_RPM_ACCOUNT_LINKING = 'rpmAccountLinking' as const;

export const FEATURE_TOGGLE_GROUP_CHAT = 'groupChat' as const;

export const FEATURE_TOGGLE_DAILY_IN_ACHIEVEMENT_PAGE = 'dailyChallengeInAchievementsPage' as const;
export const FEATURE_TOGGLE_IFRAME_ON_WORLD = 'iFrameOnWorldPage' as const;
export const FEATURE_TOGGLE_PREFAB_CREATOR = 'prefabCreator' as const;
export const FEATURE_TOGGLE_WORLD_CREATOR = 'worldCreator' as const;
export const FEATURE_TOGGLE_PINNED_WORLDS = 'pinnedWorlds' as const;
export const FEATURE_TOGGLE_SOFT_CURRENCY = 'softCurrency' as const;
export const FEATURE_TOGGLE_RPM_AS_REWARDS = 'rpmAsRewards' as const;

export const FEATURE_TOGGLE_ACTIVE_FRIENDS = 'activeFriends' as const;
