import cookie, { CookieSerializeOptions } from 'cookie';
import { config } from 'config';
import fetch from 'isomorphic-unfetch';
import { decodeJWT } from 'utils/jwt';
import { isClientSide } from 'utils/isClientSide';
import Router from 'next/router';
import { isObject } from './utils';
import { parseCookies } from './parseCookies';

const SESSION_TOKEN_MAX_AGE = 30 * 24 * 60 * 60; // 30 days

type AuthCookies = {
  token: string;
  userId: string;
  refreshToken?: string;
};

const getDefaultCookieOptions = (): CookieSerializeOptions => ({
  domain: config.environment === 'development' ? window.location.hostname : `${config.domain}`,
  path: '/',
  maxAge: SESSION_TOKEN_MAX_AGE,
});

const userCookieDefaultOptions = (): CookieSerializeOptions => ({
  ...getDefaultCookieOptions(),
  sameSite: 'none',
  secure: true,
});

export const removeAuthCookies = () => {
  document.cookie = cookie.serialize('token', '', { ...getDefaultCookieOptions(), maxAge: -1 });
  document.cookie = cookie.serialize('userId', '', { ...getDefaultCookieOptions(), maxAge: -1 });
  document.cookie = cookie.serialize('refreshToken', '', { ...getDefaultCookieOptions(), maxAge: -1 });
};

export const removeAuthLocalStorage = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('userId');
  localStorage.removeItem('refreshToken');
};

export const setAuthCookies = ({ token, userId, refreshToken }: AuthCookies) => {
  // Set 'token', 'userId' and 'refreshToken' and 'refreshToken' in cookies
  document.cookie = cookie.serialize('token', token, userCookieDefaultOptions());
  document.cookie = cookie.serialize('userId', userId.toString(), userCookieDefaultOptions());
  if (refreshToken) {
    document.cookie = cookie.serialize('refreshToken', refreshToken, userCookieDefaultOptions());
  }

  if (window.gtag) {
    window.gtag('set', { user_id: userId });
  }
};

export const setAuthLocalStorage = ({ token, userId, refreshToken }: AuthCookies) => {
  window.localStorage.setItem('token', token);
  window.localStorage.setItem('userId', userId);
  if (refreshToken) {
    window.localStorage.setItem('refreshToken', refreshToken);
  }
};

const isRefreshTokenData = (result?: unknown): result is { data: { refreshAccessToken: string } } => {
  if (!isObject(result) || !isObject(result.data)) {
    return false;
  }

  return 'refreshAccessToken' in result.data;
};
/** Uses refresh token to receive new access token */
export const refreshAccessToken = async (refreshToken: string) => {
  if (!refreshToken || hasExpired(refreshToken)) {
    console.info('Could not refresh access token: Refresh token is missing or has expired');
    return null;
  }

  const URL = `${config.settings.apiUrl}/gql`;
  const query = `mutation RefreshAccessToken($refreshToken: String!) { refreshAccessToken(refreshToken: $refreshToken) }`;
  const variables = { refreshToken };
  const response = await fetch(URL, {
    method: 'POST',
    headers: { 'content-type': 'application/json' },
    body: JSON.stringify({ query, variables }),
  });

  if (!response.ok) {
    console.error('Failed to refresh access token');
    return null;
  }

  try {
    const result = await response.json();

    return isRefreshTokenData(result) ? result.data.refreshAccessToken : '';
  } catch (error) {
    console.error(error);
    return null;
  }
};

/** Checks if JWT token has expired, including optional TTL margin
 * @param token JWT token
 * @param margin TTL margin in seconds (default: 0)
 *
 * @returns true if token has expired, false otherwise
 */
export const hasExpired = (token: string, margin = 0): boolean => {
  const jwt = decodeJWT(token);
  if (!jwt?.exp) throw new Error('Cannot determine expiration date of JWT token');
  const tokenTTL = jwt.exp;
  const localTTL = tokenTTL - margin;
  const now = Date.now() / 1000;
  return now >= localTTL;
};

export const setJwtCookie = (jwt: string) => {
  // Extract user ID and expiration date from JWT
  const { sub: userId, exp: expiresAt } = decodeJWT(jwt);

  // Set cookie params
  const params = {
    expiresAt,
    domain: config.environment === 'development' ? window.location.hostname : `.${config.domain}`,
    path: '/',
  };

  // Store token and userId cookies
  document.cookie = cookie.serialize('token', jwt, params);
  document.cookie = cookie.serialize('userId', userId as string, params);
};

export const getTokenFromAppContext = appContext => {
  const cookieString = appContext?.ctx?.req?.headers?.cookie;
  if (cookieString) {
    return cookie.parse(cookieString).token;
  }
};

export const getAuthTokens = (cookies?: Record<string, string>) => {
  const parsedCookies = parseCookies();

  let token: string | null = cookies?.token ?? parsedCookies.token ?? null;
  let refreshToken: string | null = cookies?.refreshToken ?? parsedCookies.refreshToken ?? null;

  if (isClientSide() && !token && !refreshToken) {
    token = window.localStorage.getItem('token') ?? null;
    refreshToken = window.localStorage.getItem('refreshToken') ?? null;
  }
  return { token, refreshToken };
};

/**
 * Gets the current accessToken or refreshes it if it has expired.
 *
 * @param cookies
 * @returns
 */
export const refreshAccessTokenIfExpired = async (cookies?: Record<string, string>) => {
  try {
    const TTL_MARGIN_SECONDS = 10;
    const { token: accessToken, refreshToken } = getAuthTokens(cookies);

    if (accessToken && !hasExpired(accessToken, TTL_MARGIN_SECONDS)) {
      return accessToken;
    }

    if (!refreshToken) {
      return null;
    }

    // Ensure that token type is 'refresh token'
    const decoded = decodeJWT(refreshToken);

    if (decoded?.type !== 'refresh') {
      throw new Error('Wrong token type: should be refresh token');
    }

    const newAccessToken = await refreshAccessToken(refreshToken);

    // On refresh success -> update cookies and auth header
    if (newAccessToken) {
      // Update cookies and auth header
      storeCredentials(newAccessToken, refreshToken);
      return newAccessToken;
    }

    gracefullyLogout();

    return null;
  } catch (error) {
    console.error(error);
    return null;
  }
};
export const gracefullyLogout = () => {
  //TODO This is not gracefull. In the future we need to inform the user that they have been logged.
  removeAuthCookies();
  removeAuthLocalStorage();
  console.info('Invalid refresh token! Purging tokens from browser storage.');
  Router.push('/');
  Router.reload();
};

const storeCredentials = (accessToken: string, refreshToken: string) => {
  const decodedNewAccessToken = decodeJWT(accessToken);

  // Update cookies
  if (isClientSide()) {
    setAuthCookies({
      token: accessToken,
      userId: decodedNewAccessToken?.sub as string,
      refreshToken,
    });

    setAuthLocalStorage({
      token: accessToken,
      userId: decodedNewAccessToken?.sub as string,
      refreshToken,
    });
  }
};
