import { useMe } from 'hooks/useMe';
import { useModal } from 'lib/useModal';
import { useEffectOnce } from 'lib/useOnMount';
import { usePreviousValue } from 'lib/usePreviousValue';
import { useRouter } from 'next/dist/client/router';
import { useEffect, useState } from 'react';
import { AuthModal } from './AuthModal';
import { AuthModalActionType, UseAuthModalOptions } from './AuthModal.types';

export const useAuthModal = (options?: UseAuthModalOptions) => {
  const [state, setState] = useState<AuthModalActionType | null>(null);
  const prevState = usePreviousValue(state);

  const { open, close } = useModal(
    <AuthModal
      type={state}
      onClose={() => {
        setState(null);
        close();
        options?.onClose?.();
      }}
      navigate={destination => setState(destination)}
      options={options}
    />
  );

  useEffect(() => {
    if (state !== null && state !== prevState) {
      open();
    }
  }, [state, prevState, open]);

  const openModal = (modal: AuthModalActionType) => {
    setState(modal);
  };

  return {
    state,
    open: openModal,
  };
};

const PageLoadAuthModal = () => {
  const authModal = useAuthModal();

  const router = useRouter();
  const me = useMe();

  useEffectOnce(() => {
    if (authModal.state) {
      return;
    }

    if (router.query.action === 'signin' && !me.isLoggedIn) {
      authModal.open('LOGIN');
    }

    if (router.query.action === 'sign-up' && !me.isLoggedIn) {
      authModal.open('SIGN_UP');
    }
  });

  return null;
};

export default PageLoadAuthModal;
