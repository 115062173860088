import { GTagEvent } from 'lib/gtag';

type Message =
  | 'LOGIN_SUCCESS'
  | 'RELOAD_AVATAR'
  | 'CLOSE_OVERLAY'
  | { type: 'LOGIN_WITH_DETAILS'; payload: { token: string; refreshToken: string } }
  | { type: 'hdkCommand'; command: string; args: unknown[] }
  | { type: 'gtagRecordEvent'; value: GTagEvent };

export const postMessageToEngine = (message: Message) => {
  window?.parent.postMessage(message, '*');
};
