import { Close } from '@hiberworld/icons';
import { waitForAnimationsToComplete } from 'hooks/useDialog';
import { DialogHTMLAttributes, ReactNode, useEffect, useRef } from 'react';
import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 0.75;
  }

`;

const DialogStyle = styled.dialog`
  position: fixed;
  margin: auto;
  display: block;
  inset: 0;
  padding: 0;
  min-width: min(90vw, 440px);
  scale: 1;
  border-radius: 0.5rem;
  border: ${({ theme }) => theme.colors.dialog.border};
  border-width: 0px;
  background-color: ${({ theme }) => theme.colors.dialog.background};
  transition: opacity 0.3s ease-out, scale 0.2s ease-out;
  box-shadow: 0px 8px 16px 0px rgba(27, 28, 32, 0.2);
  overflow: auto;

  &:not([open]) {
    pointer-events: none;
    opacity: 0;
    scale: 0.001;
  }
  &::backdrop {
    animation: ${fadeIn} 0.1s ease-out;
    background: ${({ theme }) => theme.colors.dialog.backdrop};
  }
`;

const Inner = styled.div`
  padding: 1.5rem 2rem;
  @media (max-width: ${({ theme }) => theme.breakpoint.mediumSmall}) {
    padding: 1rem;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 1.25rem;
  right: 1rem;
  background: transparent;
  padding: 0;
  color: ${({ theme }) => theme.colors.text.default};
  font-size: 1rem;
  border: none;
  &:hover {
    cursor: pointer;
  }
`;

export type DialogProps = {
  id: string;
  onClose?: () => void;
  children?: ReactNode;
  stayOpenOnOutsideClick?: boolean;
} & DialogHTMLAttributes<HTMLDialogElement>;

export const Dialog = ({ id, onClose, children, stayOpenOnOutsideClick, ...props }: DialogProps) => {
  const ref = useRef<HTMLDialogElement>(null);

  const close = async () => {
    onClose?.();
    await waitForAnimationsToComplete(ref.current as Element);
    ref.current?.setAttribute('inert', '');
    ref.current?.close();
  };

  const backdropClick = (event: MouseEvent) => {
    const dialog = event.target as HTMLDialogElement;
    if (dialog.nodeName === 'DIALOG') {
      close();
    }
  };

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    if (stayOpenOnOutsideClick) {
      return;
    }

    ref.current.addEventListener('click', backdropClick);

    return () => {
      ref.current?.removeEventListener('click', backdropClick);
    };
  }, [ref.current]);

  return (
    <DialogStyle id={id} ref={ref} {...props}>
      <Inner>
        <CloseButton>
          <Close onClick={close} title="Close modal" />
        </CloseButton>
        {children}
      </Inner>
    </DialogStyle>
  );
};
