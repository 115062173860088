import { useApolloClient } from '@apollo/client';
import CreateAccountForm from 'views/forms/CreateAccountForm/CreateAccountForm';
import { Spacer } from 'components/layouts/Spacer';
import { AuthResponse, AvatarProvider } from 'generated/graphql';
import { useCreateDefaultAvatar } from 'hooks/useCreateDefaultAvatar';
import { useMe } from 'hooks/useMe';
import { setAuthCookies, setAuthLocalStorage } from 'lib/auth';
import { postMessageToEngine } from 'lib/postMessageToEngine';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { Modal } from '../Modal';
import * as S from '../Modal.styles';
import { SignUpWithEmailModalProps } from './AuthModal.types';
import { useDive } from 'hooks/useDive';
import { DEFAULT_LOGIN_HEADLINE } from './AuthModal';

export const SignUpWithEmailModal = ({
  onClose,
  handleBack,
  navigateToLogin,
  navigateToUsername,
  onSignUpCompleted,
  keepOpenUntilNavigation,
  hideLoginOption,
}: SignUpWithEmailModalProps) => {
  const { me, isLoggedIn } = useMe();
  const router = useRouter();
  const client = useApolloClient();
  const [createDefaultAvatar] = useCreateDefaultAvatar();
  const [loading, setLoading] = useState(false);
  const createDaoGame = router?.query.createDaoGame;
  const { googleId } = me ?? {};
  const dive = useDive();

  useEffect(() => {
    if (!keepOpenUntilNavigation && isLoggedIn && !googleId && !createDaoGame && !loading) {
      onClose?.();
    }
  }, [googleId, isLoggedIn, createDaoGame, onClose, loading, keepOpenUntilNavigation]);

  const onRegisterSuccess = async (authResponse: AuthResponse, type: string) => {
    const token = authResponse?.token || authResponse?.accessToken;
    const user = authResponse?.user;
    const refreshToken = authResponse?.refreshToken as string;

    setLoading(true);

    if (!token || !user) {
      return;
    }

    setAuthCookies({
      token,
      userId: user?.id,
      refreshToken,
    });

    setAuthLocalStorage({
      token,
      userId: user?.id,
      refreshToken,
    });

    client.clearStore();
    client.cache.reset();
    client.resetStore();

    if (type !== 'email') {
      navigateToUsername();
      return;
    }

    await createDefaultAvatar({ provider: AvatarProvider.ReadyPlayerMe });

    await onSignUpCompleted?.();

    postMessageToEngine({ type: 'LOGIN_WITH_DETAILS', payload: { token, refreshToken } });
    postMessageToEngine('LOGIN_SUCCESS');

    onClose?.();

    setLoading(false);
  };

  const onNavigateToLogin = () => {
    dive.trackModalOpen({ name: 'login', origin: 'sign_up', description: DEFAULT_LOGIN_HEADLINE });
    navigateToLogin();
  };

  return (
    <Modal onBack={handleBack} onClose={onClose}>
      <S.RpmFrontLight top="-132px" src="https://cdn.hibervr.com/static/images/Rpm_02_FrontLight.png" />
      <S.Headline>Sign up for HiberWorld</S.Headline>
      <Spacer height={16} />
      <CreateAccountForm busy={loading} onRegisterSuccess={onRegisterSuccess} />
      <Spacer height={20} />
      <S.Text fontSize="12px" lineHeight="18px">
        By continuing, you are agreeing to the HiberWorld
        <S.Link
          fontSize="12px"
          bold
          color="white"
          rel="noreferrer"
          target="_blank"
          href="https://hiber3d.com/terms-of-use/">
          {' '}
          Terms of Use{' '}
        </S.Link>
        and
        <S.Link
          fontSize="12px"
          bold
          color="white"
          rel="noreferrer"
          target="_blank"
          href="https://hiber3d.com/privacy-policy/">
          {' '}
          Privacy Policy{' '}
        </S.Link>
      </S.Text>
      {!hideLoginOption && (
        <>
          <Spacer height={40} />
          <S.Text fontSize="16px">
            Already have an account?{' '}
            <S.Link color="white" fontSize="16px" bold onClick={onNavigateToLogin}>
              Log In
            </S.Link>
          </S.Text>
        </>
      )}
    </Modal>
  );
};
