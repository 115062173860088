import { Chevron } from '@hiberworld/icons';
import styled from 'styled-components';

export const DownIcon = styled(Chevron)`
  position: absolute;
  right: 1rem;
  rotate: 180deg;
  path {
    stroke-width: 3px;
  }
  pointer-events: none;
`;

export const HiberSelect = styled.select`
  position: relative;
  width: 100%;
  text-align: left;
  padding-left: 1rem;
  outline: none;
  appearance: none;
  border-radius: 8px;
  padding: 0.75rem;
  background-color: ${({ theme }) => theme.colors.input.default.background};
  border: ${({ theme }) => theme.colors.input.default.border};
  color: ${({ theme }) => theme.colors.input.default.text};
  font-size: ${({ theme }) => theme.typography.body.medium.size.web};
  font-weight: ${({ theme }) => theme.typography.body.medium.weight};
  line-height: ${({ theme }) => theme.typography.body.medium.lineHeight};
  font-style: ${({ theme }) => theme.typography.body.medium.style};

  &:focus {
    background-color: ${({ theme }) => theme.colors.input.focus.background};
    border: ${({ theme }) => theme.colors.input.focus.border};
    color: ${({ theme }) => theme.colors.input.focus.text};
  }
  &:focus-visible {
    outline: none;
  }
  &:default {
    color: blue;
  }

  &:not(:focus):not(:placeholder-shown):invalid {
    background-color: ${({ theme }) => theme.colors.input.error.background};
    border: ${({ theme }) => theme.colors.input.error.border};
    color: ${({ theme }) => theme.colors.input.error.text};
  }
`;
