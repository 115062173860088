import styled from 'styled-components';
import InputField from '../fields/InputField';

export const ButtonSignup = styled.button`
  width: 100%;
`;

export const TermStyle = styled.a`
  color: #e9e9ec;
  font-weight: bold;
`;

export const RandomizeStyle = styled.i`
  position: absolute;
  top: 10px;
  right: 20px;

  cursor: pointer;
  &:hover {
    path {
      transition: all 0.1s linear;
      stroke: ${({ theme }) => theme.primaryColor.red};
      fill: ${({ theme }) => theme.primaryColor.red};
    }
  }
`;

export const CreateAccountFormStyledForm = styled.form`
  .radio-select {
    background: rgba(228, 228, 228, 0.5);
    display: flex;
    align-items: center;
    padding-left: 1rem;
    span.label {
      font-size: 1.1rem;
    }
    label {
      display: inline-block;
      .option {
        width: 50px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        &.checked i {
          color: ${props => props.theme.sass.hiber_green};
          border-color: ${props => props.theme.sass.hiber_green};
        }
        i {
          width: 25px;
          height: 25px;
          cursor: pointer;
          color: ${props => props.theme.sass.hiber_gray};
          transition: color, border 0.2s ease;
          border: 1px solid ${props => props.theme.sass.hiber_gray};
          padding: 0.2rem;
          display: flex;
          justify-content: center;
          align-items: center;
          &::before {
            transform: scale(1.2);
          }
        }
      }
    }
  }

  .warning {
    margin-top: -0.7rem;
    margin-bottom: 0.7rem;
    color: #8e8a8a;
  }
  .email-button-signup {
    width: 100%;
  }
`;

export const StyledInputField = styled(InputField)`
  background-color: ${({ theme }) => theme.primaryColor.veryDark};
  border: 0px solid #bababa;
  border-radius: 8px;
  box-shadow: none;
  box-sizing: border-box;
  color: #ffffff;
  font-size: 1.1rem;
  padding: 0.7rem;
  margin: 0 0 0.3rem 0;
  position: relative;
  resize: none;
  top: -2px;
  vertical-align: top;
  width: 100%;
`;
