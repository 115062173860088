import styled, { CSSProperties } from 'styled-components';
import { Theme } from '@hiberworld/theme';

type ParagraphProps = {
  size: keyof Theme['typography']['body'];
  color?: string;
  align?: 'center';
} & Pick<
  CSSProperties,
  | 'wordBreak'
  | 'textAlign'
  | 'maxWidth'
  | 'marginTop'
  | 'marginBottom'
  | 'marginRight'
  | 'marginLeft'
  | 'margin'
  | 'fontWeight'
>;

export const Paragraph = styled.p<ParagraphProps>`
  /* font-family: ${({ theme, size }) => theme.typography.body[size].font.web}; */
  font-weight: ${({ theme, fontWeight, size }) => fontWeight ?? theme.typography.body[size].weight};
  font-size: ${({ theme, size }) => theme.typography.body[size].size.web};
  line-height: ${({ theme, size }) => theme.typography.body[size].lineHeight};
  font-style: ${({ theme, size }) => theme.typography.body[size].style};
  margin: 0;
  color: ${({ theme, color }) => color ?? theme.colors.paragraph.text};
  text-align: ${({ textAlign, align }) => textAlign ?? align ?? 'left'};
  max-width: ${({ maxWidth }) => maxWidth ?? 'none'};
  margin: ${({ margin }) => margin || ''};
  margin-top: ${({ marginTop }) => marginTop || ''};
  margin-bottom: ${({ marginBottom }) => marginBottom || ''};
  margin-left: ${({ marginLeft }) => marginLeft || ''};
  margin-right: ${({ marginRight }) => marginRight || ''};
  word-break: ${({ wordBreak }) => wordBreak || ''};
`;
