import { DialogProps } from 'components/layouts/Dialog/Dialog';
import { User } from 'generated/graphql';

export type BossUserModalProps = {
  close?: () => void;
  user: Pick<
    User,
    | 'id'
    | 'username'
    | 'email'
    | 'isDeveloper'
    | 'isBetatester'
    | 'isEmployee'
    | 'isVerified'
    | 'level'
    | 'role'
    | 'birthdate'
  >;
  me?: User | null;
} & DialogProps;

export const UserRole = {
  USER: 0,
  MODERATOR: 3,
  ADMIN: 4,
  SYSTEM_OWNER: 5,
};

export const DisplayUserRole = {
  0: 'USER',
  3: 'MODERATOR',
  4: 'ADMIN',
  5: 'SYSTEM_OWNER',
};

export type BossUserModalForm = {
  username: string;
  email: string;
  id: number;
  password: string;
  verified: boolean;
  employee: boolean;
  developer: boolean;
  betatester: boolean;
  level: string;
  role: string;
};
