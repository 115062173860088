import { Search } from '@hiberworld/icons';
import { SearchField } from 'components/legacy/fields';
import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';
import styled, { useTheme } from 'styled-components';

const SearchBarContainer = styled.div<{ isOpen?: boolean }>`
  @media (max-width: ${({ theme }) => theme.breakpoint.medium}) {
    display: grid;
    align-items: flex-end;
    grid-template-rows: ${({ isOpen }) => (isOpen ? '1fr' : '0fr')};
    grid-template-columns: 1fr;
    position: absolute;
    left: 0;
    width: 100%;
    top: 100%;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
    padding: ${({ isOpen }) => (isOpen ? '1rem' : '0 1rem')};
    background-color: ${({ theme }) => theme.colors.navigation.background};
  }
`;

const SearchFormContainer = styled.div<{ isOpen?: boolean }>`
  @media (max-width: ${({ theme }) => theme.breakpoint.medium}) {
    overflow: hidden;
    grid-row: 1 / span 2;
  }
`;

export const SearchBar = ({ isOpen }: { isOpen?: boolean }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const router = useRouter();
  const theme = useTheme();

  const [query, setQuery] = useState(router.query?.searchTerm as string);

  const [loading, setLoading] = useState(false);

  const handleClear = () => {
    inputRef?.current?.focus();
    setQuery('');
  };

  const handleSearch = (text: string) => {
    if (text) {
      setLoading(true);
      router.push(`/search/${text}?type=worlds`).then(() => setLoading(false));
    }
  };

  useEffect(() => {
    if (inputRef.current && isOpen) {
      inputRef.current.focus();
    }
  }, [isOpen]);

  return (
    <SearchBarContainer isOpen={isOpen}>
      <SearchFormContainer isOpen={isOpen}>
        <SearchField
          value={query}
          onEnter={handleSearch}
          backgroundColor={theme.colors.input.search.background}
          borderColor={theme.colors.input.search.border}
          Icon={<Search size="1.5rem" />}
          placeholder="Search"
          data-cy="topBarSearch"
          borderRadius={8}
          setValue={setQuery}
          ref={inputRef}
          onClear={handleClear}
          loading={loading}
        />
      </SearchFormContainer>
    </SearchBarContainer>
  );
};
