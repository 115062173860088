import { EMPLOYEE_TOOLTIP, VERIFIED_TOOLTIP } from './VerifiedBadge.constants';
import { VerifyBadgeProps } from './VerifyBadge.types';
import { FlexBox } from 'components/layouts/FlexBox';
import { ReactNode } from 'react';
import { Tooltip } from 'components/common/Tooltip';
import { Employee, Verified } from '@hiberworld/icons';
import { useTheme } from 'styled-components';

type BadgeProps = {
  Icon: ReactNode;
  toolTip?: string;
};

const Badge = ({ Icon, toolTip }: BadgeProps) => {
  if (!toolTip) {
    return <>{Icon}</>;
  }
  return <Tooltip text={toolTip}>{Icon}</Tooltip>;
};

export const VerifiedBadge = ({ isEmployee, isVerified, size = 16 }: VerifyBadgeProps) => {
  const theme = useTheme();

  if (!isEmployee && !isVerified) {
    return null;
  }
  return (
    <FlexBox data-cy="Verified Employer" paddingBottom="2px">
      {isEmployee && (
        <Badge
          toolTip={EMPLOYEE_TOOLTIP}
          Icon={<Employee data-testid="employee-badge" size={size} color={theme.colorTheme.accents.green.primary} />}
        />
      )}
      {isVerified && (
        <Badge
          toolTip={VERIFIED_TOOLTIP}
          // TODO: Remove hardcoded color, when icons lib is updated
          Icon={<Verified data-testid="verified-badge" size={size} color="#0078E8" />}
        />
      )}
    </FlexBox>
  );
};
