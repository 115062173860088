import { Button, ButtonProps } from 'components/common/buttons/Button';
import styled, { css } from 'styled-components';

const Grid = styled.span`
  display: grid;
  transition: grid-template-columns ${({ theme }) => theme.transition.fast} ease-out;
  grid-template-columns: 0fr;
  transition-delay: 0.1s;
`;

const Column = styled.span`
  overflow: hidden;
  grid-column: 1 / span 2;
`;

const expandedButtonCss = css`
  gap: 0.25rem;
  padding: 0 1rem 0 0.75rem;
  transition-delay: 0s;

  ${Grid} {
    grid-template-columns: 1fr;
    transition-delay: 0s;
  }
`;

const StyledButton = styled(Button)<{ collapsible: boolean }>`
  transition: all ${({ theme }) => theme.transition.fast} ease-out;
  transition-delay: 0.1s;
  gap: 0;
  padding: 0 7px;

  @media (min-width: 1100px) {
    ${({ collapsible }) =>
      collapsible
        ? css`
            &:hover {
              ${expandedButtonCss}
            }
          `
        : expandedButtonCss};
  }
`;

export const CollapsibleTextButton = ({ text, collapsible, ...props }: ButtonProps & { collapsible: boolean }) => (
  <StyledButton collapsible={collapsible} {...props}>
    <Grid>
      <Column>{text}</Column>
    </Grid>
  </StyledButton>
);
