import { FetchResult } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import * as ModalStyles from 'components/legacy/modals/Modal.styles';
import { Spacer } from 'components/layouts/Spacer';
import { LoginInput, SigninMutation, SigninMutationResult } from 'generated/graphql';
import { useSignin } from 'lib/useSignin';
import { useSubmitForm } from 'lib/useSubmitForm';
import React from 'react';
import { Controller } from 'react-hook-form';
import ErrorField from '../fields/ErrorField';
import { schema } from './LoginForm.schema';
import { LoginFormProps, LoginInputForm } from './LoginForm.types';
import * as S from './LoginForm.styles';
import { Button } from 'components/common/buttons/Button';

export const LoginForm: React.FC<LoginFormProps> = ({ onLoginSuccess, onForgotPassword }) => {
  const {
    form,
    loading: submitting,
    submitGqlForm,
  } = useSubmitForm<LoginInputForm>({
    mode: 'onChange',
    defaultValues: { username: '', password: '' },
    resolver: yupResolver(schema),
  });
  const signin = useSignin();

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = form;

  const onSubmit = async (values: LoginInput) => {
    const { password, username } = values;
    const data = {
      username,
      password,
    };

    const queries: Promise<FetchResult<SigninMutation>>[] = [];

    queries.push(signin.signin(data));

    const res = await submitGqlForm<SigninMutation, SigninMutationResult>(queries);

    if (res.success) {
      const loginResponse = res?.data?.[0]?.data?.loginUser;
      onLoginSuccess(loginResponse);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} id="form1" method="post">
      <Controller
        name="username"
        control={control}
        render={({ field, fieldState: { isDirty, error } }) => (
          <S.InputField
            input={field}
            label="Email / Username"
            ariaLabel="username"
            meta={{
              touched: isDirty,
              error,
            }}
          />
        )}
      />
      <Spacer height="0.5rem" />
      <Controller
        name="password"
        control={control}
        render={({ field, fieldState: { isDirty, error } }) => (
          <S.InputField
            input={field}
            type="password"
            label="Password"
            ariaLabel="password"
            meta={{
              touched: isDirty,
              error,
            }}
          />
        )}
      />
      <Spacer height={8} />
      <ModalStyles.Link fontSize="14px" color="textGray" underline onClick={onForgotPassword}>
        Forgot password?
      </ModalStyles.Link>
      <Spacer height={20} />
      <Button
        text="Log In"
        busy={submitting}
        disabled={submitting}
        type="submit"
        variety="primary"
        size="medium"
        fullWidth
        data-cy="LoginFormLoginButton"
      />
      <Spacer height={8} />

      {errors?.generic?.message && <ErrorField error={errors.generic.message} />}
    </form>
  );
};
