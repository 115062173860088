import { yupResolver } from '@hookform/resolvers/yup';
import Button from 'components/legacy/Button';
import { Spacer } from 'components/layouts/Spacer';
import { Label } from 'components/typography';
import BirthDaySelect from 'views/forms/fields/BirthdaySelect';
import ErrorField from 'views/forms/fields/ErrorField';
import { Checkbox } from 'components/inputs/Checkbox/Checkbox';
import { FEATURE_TOGGLE_EXTERNAL_DEVELOPER_SIGNUP } from 'constants/featureToggle.constants';
import cookie from 'cookie';
import { SignUpWithEmailMutation, SignUpWithEmailMutationResult, UserType } from 'generated/graphql';
import { useDive } from 'hooks/useDive';
import { useMe } from 'hooks/useMe';
import { gtagRecordEvent } from 'lib/gtag';
import { useFeatureToggle } from 'lib/useFeatureToggles';
import { useRandomizedUsernames } from 'lib/useRandomizedUsername';
import { useSignUpWithEmail } from 'lib/useSignUpWithEmail';
import { useSubmitForm } from 'lib/useSubmitForm';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { getAgeFromBirthdate } from 'utils/getAgeFromBirthdate';
import { isOverAge } from 'utils/validateAge';
import { schema } from './CreateAccountForm.schema';
import * as S from './CreateAccountForm.styles';
import { CreateAccountFormData, CreateAccountFormProps } from './CreateAccountForm.types';
import { Shuffle } from '@hiberworld/icons';

const CreateAccountForm = ({ onRegisterSuccess, busy }: CreateAccountFormProps) => {
  const {
    submitGqlForm,
    loading: submitting,
    form,
  } = useSubmitForm<CreateAccountFormData>({
    mode: 'onSubmit',
    defaultValues: {
      username: '',
      email: '',
      password: '',
      day: '',
      month: '',
      year: '',
      birthdate: '',
      type: UserType.Registered,
    },
    resolver: yupResolver(schema),
  });
  const { signUpWithEmail } = useSignUpWithEmail();
  const { control, setValue, handleSubmit } = form;
  const router = useRouter();
  const { track, trackUserCreated, trackUserSignedUp } = useDive();
  const { me } = useMe();
  const [randomCount, setRandomCount] = useState(0);
  const { usernames, refetch, loading } = useRandomizedUsernames();
  const birthdateValue = form.getValues().birthdate;

  const selectNextUsername = useCallback(() => {
    let selectableUsernames = usernames;
    if (me && !me.isRegistered && me.username) {
      selectableUsernames = [me.username, ...usernames];
    }
    setValue('username', selectableUsernames[randomCount]);
  }, [setValue, me, usernames, randomCount]);

  useEffect(() => {
    if (birthdateValue) {
      track('fieldEntered', {
        value: String(birthdateValue)?.substring(0, 10),
        page_id: router.route,
        name: 'birthdate',
      });
    }
  }, [birthdateValue, track, router.route]);

  useEffect(() => {
    if (usernames?.length > 0) {
      selectNextUsername();
    }
  }, [selectNextUsername, usernames]);

  useEffect(() => {
    if (!busy) {
      setRandomCount(s => s + 1);
    }
  }, [loading, busy]);

  const developerSignupEnabled = useFeatureToggle(FEATURE_TOGGLE_EXTERNAL_DEVELOPER_SIGNUP).isEnabled;
  const [developerMode, setDeveloperMode] = useState(false);
  const [developerAgreementAccepted, setDeveloperAgreementAccepted] = useState(false);

  const toggleDeveloperSignUp = () => {
    setDeveloperMode(!developerMode);
  };

  const randomizeUsername = () => {
    if (randomCount > 28) {
      refetch();
    }
    if (usernames) {
      selectNextUsername();
      setRandomCount(randomCount + 1);
      if (randomCount > 28) {
        setRandomCount(0);
      }
    }
  };

  async function onSubmit(values: CreateAccountFormData) {
    const birthdate = new Date(values.birthdate);
    const isOverThirteen = isOverAge(birthdate);
    const email = values.email ? values.email : null;
    const wantsToBeDeveloper = developerAgreementAccepted && developerMode;

    const type = wantsToBeDeveloper ? UserType.ExternalDeveloper : UserType.Registered;

    const payload = {
      birthdate,
      email: isOverThirteen ? email : null,
      username: values.username,
      password: values.password,
      type,
    };

    const res = await submitGqlForm<SignUpWithEmailMutation, SignUpWithEmailMutationResult>([
      signUpWithEmail({
        payload,
      }),
    ]);

    if (res.success) {
      const authResponse = res?.data[0]?.data?.createUser;
      const user = authResponse?.user;
      const cookies = cookie.parse(document.cookie);

      if (user) {
        gtagRecordEvent({ event: 'account_created', provider: email ? 'email' : 'username' });
        trackUserCreated({ guestId: cookies.rid, age: getAgeFromBirthdate(birthdate), user });
        trackUserSignedUp({ newUserId: user.id, loginType: 'user', thirdPartyId: '', username: user.username, user });
      }

      if (authResponse) {
        onRegisterSuccess(authResponse, 'email');
      }
    } else {
      form.setError('generic', {
        type: 'manual',
        message: 'Whoops, looks like something went wrong.',
      });
    }
  }

  const canSubmit = !submitting;

  return (
    <S.CreateAccountFormStyledForm onSubmit={handleSubmit(onSubmit)} id="form1" method="post" autoComplete="off">
      <Label size="large" style={{ textAlign: 'left' }}>
        Birthday
      </Label>
      <Spacer height={8} />
      <Controller
        name="birthdate"
        control={control}
        render={({ fieldState }) => (
          <BirthDaySelect form={form} meta={{ error: fieldState.error, touched: fieldState.isTouched }} />
        )}
      />
      <Spacer height={20} />
      <section style={{ position: 'relative' }}>
        <Controller
          name="username"
          control={control}
          render={({ field, fieldState }) => (
            <S.StyledInputField
              input={field}
              label="Username"
              ariaLabel="username"
              meta={{ error: fieldState.error, touched: fieldState.isTouched }}
            />
          )}
        />
        <S.RandomizeStyle title="Randomize username" onClick={randomizeUsername}>
          <Shuffle size={24} color="#fff" />
        </S.RandomizeStyle>

        <Spacer height={15.5} />

        <Controller
          name="email"
          control={control}
          render={({ field, fieldState }) => (
            <S.StyledInputField
              input={field}
              label="Email"
              ariaLabel="email"
              meta={{ error: fieldState.error, touched: fieldState.isTouched }}
            />
          )}
        />
        <Spacer height={15.5} />

        <Controller
          defaultValue=""
          name="password"
          control={control}
          render={({ field, fieldState }) => (
            <S.StyledInputField
              input={field}
              label="Password"
              type="password"
              ariaLabel="password"
              meta={{ error: fieldState.error, touched: fieldState.isTouched }}
            />
          )}
        />
      </section>
      <Spacer height={15.5} />

      <Button
        busy={submitting || busy}
        disabled={!canSubmit}
        type="submit"
        className="button margin-bottom red fullsize"
        text="Sign Up"
        data-cy="createAccountButton"
      />

      {developerSignupEnabled && (
        <div>
          <Spacer height={20} />
          {developerMode ? (
            <>
              <Label color="white" size="small">
                Sign up as a developer
              </Label>
              <Spacer height={10} />
              <Label size="small">
                <Checkbox
                  checked={developerAgreementAccepted}
                  onChange={e => {
                    setDeveloperAgreementAccepted(e.target.checked);
                  }}
                />
                I have read and accept the Developer agreement
              </Label>
              <Spacer height={10} />
              <Label color="red" size="small" onClick={toggleDeveloperSignUp}>
                No, take me back!
              </Label>
            </>
          ) : (
            <Label color="green" size="small" onClick={toggleDeveloperSignUp}>
              Sign up as a developer
            </Label>
          )}
        </div>
      )}

      {form?.formState?.errors?.generic?.message && <ErrorField error={form.formState.errors.generic.message} />}
    </S.CreateAccountFormStyledForm>
  );
};

export default CreateAccountForm;
