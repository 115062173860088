export const capitalizeFirstLetterInSentence = (str = '') => {
  if (!str) {
    return '';
  }

  const words = str.split(' ');
  const firstWord = words[0].charAt(0).toUpperCase() + words[0].slice(1).toLowerCase();
  words.shift();

  if (words.length === 0) {
    return firstWord;
  }

  return `${firstWord} ${words.join(' ')}`;
};
