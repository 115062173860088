import { Search } from '@hiberworld/icons';
import { PageContentWrapper } from 'components/layouts/PageContentWrapper/PageContentWrapper';
import styled from 'styled-components';

export const TOPBAR_HEIGHT = 56;

export const Header = styled(PageContentWrapper).attrs({ fullWidth: true, as: 'header', noBottomMargin: true })`
  position: fixed;
  display: grid;
  grid-template-columns: 1fr auto minmax(max-content, 1fr);
  gap: 24px;
  top: 0;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.navigation.background};
  backdrop-filter: blur(20px);
  background-color: rgba(27, 28, 32, 0.8);
  z-index: 999;
  height: ${TOPBAR_HEIGHT}px;
  align-items: center;
`;

export const SearchIcon = styled(Search)`
  display: none;

  @media (max-width: ${({ theme }) => theme.breakpoint.medium}) {
    display: block;
  }
`;
