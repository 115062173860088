import styled from 'styled-components';

export const ProgressBarWrapper = styled.div<{ backgroundColor?: string; height?: string; width?: string }>`
  background: ${({ theme, backgroundColor }) => backgroundColor || theme.primaryColor.veryDark};
  border-radius: ${({ theme }) => theme.borderRadius.large};
  width: ${({ width }) => width || '100%'};
  height: ${({ height }) => height || '8px'};
  display: flex;
`;

export const Progress = styled.div<{ progress: number; progressColor?: string }>`
  border-radius: ${({ theme }) => theme.borderRadius.large};
  background: ${({ theme, progressColor }) => progressColor || theme.primaryColor.red};
  width: ${({ progress }) => `${progress}%`};
  content: ' ';
  transition: ${({ theme }) => theme.transition.fast};
`;

type ProgressBarProps = {
  progress: number;
  backgroundColor?: string;
  progressColor?: string;
  height?: string;
  width?: string;
  htmlTitle?: string;
};

const ProgressBar = ({ progress, backgroundColor, progressColor, height, width, htmlTitle }: ProgressBarProps) => {
  return (
    <ProgressBarWrapper backgroundColor={backgroundColor} height={height} width={width} title={htmlTitle}>
      <Progress progress={progress} progressColor={progressColor} />
    </ProgressBarWrapper>
  );
};

export default ProgressBar;
