import { Theme } from '@hiberworld/theme';
import styled, { CSSProperties } from 'styled-components';

type LabelProps = { size: keyof Theme['typography']['label']; color?: string; singleLine?: boolean } & Pick<
  CSSProperties,
  'marginTop' | 'marginBottom' | 'marginRight' | 'marginLeft' | 'margin' | 'textAlign'
>;

export const Label = styled.label<LabelProps>`
  /* font-family: ${({ theme, size }) => theme.typography.label[size].font.web}; */
  font-weight: ${({ theme, size }) => theme.typography.label[size].weight};
  font-size: ${({ theme, size }) => theme.typography.label[size].size};
  line-height: ${({ theme, size, singleLine }) => (singleLine ? 'normal' : theme.typography.label[size].lineHeight)};
  font-style: ${({ theme, size }) => theme.typography.label[size].style};
  text-transform: ${({ theme, size }) => theme.typography.label[size].transform};
  color: ${({ color, theme }) => color || theme.colors.text.default};
  margin: ${({ margin }) => margin || ''};
  margin-top: ${({ marginTop }) => marginTop || ''};
  margin-bottom: ${({ marginBottom }) => marginBottom || ''};
  margin-left: ${({ marginLeft }) => marginLeft || ''};
  margin-right: ${({ marginRight }) => marginRight || ''};
  text-align: ${({ textAlign }) => textAlign || ''};
  cursor: inherit;
`;
