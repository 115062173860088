import { Theme } from '@hiberworld/theme';
import { CSSProperties, useTheme } from 'styled-components';
import { Label } from './Label';

type ErrorMessageProps = {
  message?: string;
  size?: keyof Theme['typography']['label'];
} & Pick<CSSProperties, 'marginTop' | 'marginBottom' | 'marginRight' | 'marginLeft' | 'margin'>;

export const ErrorMessage = ({ message, size = 'large', ...props }: ErrorMessageProps) => {
  const theme = useTheme();

  if (!message) {
    return null;
  }

  return (
    <Label size={size} {...props} color={theme.colors.text.error}>
      {message}
    </Label>
  );
};
