import styled from 'styled-components';

export const Input = styled.input`
  display: none;
`;

export const Toggle = styled.input`
  display: block !important;
  padding: 0;
  transition: margin 100ms;
  appearance: none;
  height: 16px;
  width: 16px;
  border-radius: 16px;
  border: none;
  margin: 0;
  margin-left: 0;
  margin-right: 32px;
  align-self: flex-start;
  background: ${({ theme }) => theme.primaryColor.textGray};
  &:checked {
    margin-left: 16px;
    margin-right: 0px;
    background: ${({ theme }) => theme.primaryColor.green};
  }
`;

export const ToggleContainer = styled.label<{ backgroundColor?: string }>`
  display: flex;
  margin: 0 !important;
  padding: 0 !important;
  flex-direction: column;
  height: 16px;
  width: 32px;
  background: ${({ theme, backgroundColor }) => backgroundColor ?? theme.primaryColor.veryDark};
  border-radius: 16px;
`;
