import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -2px;
  right: 5px;
  width: 16px;
  height: 16px;
  font-size: 10px;
  text-align: center;
  background: ${({ theme }) => theme.primaryColor.red};
  border-radius: 50%;
`;
