import { useApolloClient } from '@apollo/client';
import { Shuffle } from '@hiberworld/icons';
import { Button } from 'components/common/buttons/Button';
import { Spacer } from 'components/layouts/Spacer';
import { RandomizeStyle } from 'views/forms/CreateAccountForm/CreateAccountForm.styles';
import ErrorField from 'views/forms/fields/ErrorField';
import cookie from 'cookie';
import { useFinishRegistrationMutation, useRandomizedUsernamesLazyQuery } from 'generated/graphql';
import { useDive } from 'hooks/useDive';
import { useMe } from 'hooks/useMe';
import { gtagRecordEvent } from 'lib/gtag';
import { postMessageToEngine } from 'lib/postMessageToEngine';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import styled from 'styled-components';
import { getAgeFromBirthdate } from 'utils/getAgeFromBirthdate';
import { getGraphqlFieldErrors } from 'utils/getGraphqlFieldError';
import { Modal } from '../Modal';
import * as S from '../Modal.styles';
import { FinishRegistrationModalProps } from './AuthModal.types';

const StyledInputField = styled.input`
  background-color: ${({ theme }) => theme.primaryColor.veryDark};
  border: 0px solid #bababa;
  border-radius: 8px;
  box-shadow: none;
  box-sizing: border-box;
  color: #ffffff;
  font-size: 1.1rem;
  padding: 0.7rem;
  margin: 0 0 0.3rem 0;
  position: relative;
  resize: none;
  top: -2px;
  vertical-align: top;
  width: 100%;
`;

export const UsernameModal = ({ onClose, onSignUpCompleted }: FinishRegistrationModalProps) => {
  const { me, loading } = useMe();
  const meUserName = me?.username ?? '';
  const client = useApolloClient();
  const dive = useDive();
  const [username, setUsername] = useState(meUserName);
  const [randomCount, setRandomCount] = useState(1);
  const [finishRegistration] = useFinishRegistrationMutation();

  const initialValues = {
    username,
  };

  const [usernameError, setUsernameError] = useState<string | null>(null);

  const validateUsername = (value: string) => {
    let error = '';
    setUsernameError(null);
    if (!value) {
      error = 'Username cannot be empty';
    }
    const regExp = /^[\w]*$/g;
    if (!regExp.exec(value)) {
      error = 'Username can only contain letters, number and the character: _';
    }
    if (value.length < 3 || value.length > 18) {
      error = 'Username has to be between 3 and 18 characters';
    }
    return error;
  };

  const form = useForm({
    defaultValues: initialValues,
    resolver: async ({ username }) => {
      const validation = validateUsername(username);

      if (validation === '') {
        return { errors: {}, values: { username } };
      }

      return {
        errors: {
          username: {
            message: validation,
            type: 'pattern',
          },
        },
        values: {},
      };
    },
  });
  const [randomizeUsernames, queryResult] = useRandomizedUsernamesLazyQuery({
    onCompleted: data => {
      if (username === '' && data.randomizedUsernames) {
        const randomUsername = data.randomizedUsernames[0];
        setUsername(randomUsername);
        form.setValue('username', randomUsername);
      }
    },
  });
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (submitting || loading) {
      return;
    }

    randomizeUsernames({
      variables: { amount: 30 },
    });
  }, [loading, randomizeUsernames, submitting, username]);

  const navigateAndClose = async () => {
    await client.resetStore();
    await onSignUpCompleted?.();
    onClose();
  };

  const doFinishRegistration = async (values: { username: string }) => {
    setSubmitting(true);
    const payload = {
      username: values.username,
      hasAcceptedTOS: true,
      newsletterSignup: true,
    };
    try {
      const { data, errors } = await finishRegistration({ variables: { payload } });
      const user = data?.finishRegistration;

      if (errors?.length) {
        setUsernameError(getGraphqlFieldErrors(errors));
        setSubmitting(false);
        setUsername(values.username);
        return;
      }
      const cookies = cookie.parse(document.cookie);
      if (user?.googleId) {
        gtagRecordEvent({ event: 'account_created', provider: 'google' });
        dive.trackUserCreated({ guestId: cookies.rid, age: getAgeFromBirthdate(me?.birthdate), user });
        dive.trackUserSignedUp({
          newUserId: user.id,
          loginType: 'google',
          thirdPartyId: user.googleId,
          username: user.username,
          user,
        });
      }

      // Handle users who signed up with a wallet
      if (user?.wallets && user.wallets[0]) {
        const wallet = user?.wallets[0];
        dive.trackUserCreated({ guestId: cookies.rid, age: getAgeFromBirthdate(me?.birthdate), user });
        dive.trackUserSignedUp({
          newUserId: user.id,
          loginType: 'wallet',
          thirdPartyId: wallet.address,
          idType: wallet.type,
          username: user.username,
          user,
        });
      }
    } catch (e) {
      setUsernameError('Username already taken');
    }
    setSubmitting(false);

    postMessageToEngine('LOGIN_SUCCESS');

    navigateAndClose();
  };

  const handleSubmit = async (values: { username: string }) => {
    await doFinishRegistration(values);
  };

  const randomizeUsername = () => {
    if (randomCount > 28) {
      queryResult.refetch?.();
    }
    if (queryResult?.data?.randomizedUsernames) {
      form.setValue('username', queryResult.data.randomizedUsernames[randomCount]);
      setRandomCount(randomCount + 1);
      if (randomCount > 28) {
        setRandomCount(0);
      }
    }
    setUsernameError(null);
  };

  return (
    <Modal hideClose keepOpenOnClickOutside onClose={onClose}>
      <S.Headline>Pick a username</S.Headline>
      <Spacer height={16} />

      <S.Text fontSize="14px" lineHeight="18px">
        Choose a username, but don&apos;t use your real name!
      </S.Text>
      <Spacer height={40} />
      <form onSubmit={form.handleSubmit(handleSubmit)}>
        <section style={{ position: 'relative' }}>
          <Controller
            control={form.control}
            name="username"
            render={({ fieldState, field }) => {
              return (
                <>
                  <StyledInputField {...field} name="username" placeholder="Don't use your real name" type="text" />
                  {fieldState.error?.message && <ErrorField error={fieldState.error.message} />}
                  {usernameError && <ErrorField error={usernameError} />}
                </>
              );
            }}
          />
          <RandomizeStyle onClick={randomizeUsername}>
            <Shuffle size={24} color="#fff" />
          </RandomizeStyle>
        </section>
        <Spacer height={16} />
        <Button variety="primary" size="medium" type="submit" fullWidth text="Save" />
        <Spacer height={16} />
      </form>
    </Modal>
  );
};
