export const SoftCurrencyLogo = ({ size = 20 }: { size?: number }) => (
  <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="10" cy="10" r="10" fill="#E1A101" />
    <circle cx="10" cy="10" r="8" fill="#E9B100" />
    <circle cx="10" cy="10" r="7.5" stroke="#8D5501" strokeOpacity="0.6" />
    <path
      d="M14.2199 9.07188C14.0305 8.36005 13.6354 7.70812 13.0637 7.1781C12.2436 6.41845 11.1553 6 9.99924 6C8.84318 6 7.75454 6.41845 6.93477 7.1781C6.3624 7.70829 5.96781 8.36057 5.77837 9.07258C5.33798 9.24161 5.02535 9.60821 5 10.2847V10.8518C5 11.0163 5.03127 11.1792 5.09202 11.3312C5.15277 11.4832 5.24181 11.6213 5.35406 11.7377C5.4663 11.854 5.59956 11.9463 5.74622 12.0092C5.89287 12.0722 6.05006 12.1045 6.20879 12.1045H6.87714L6.90114 8.99166L6.52091 8.95225C6.47512 8.95225 6.42983 8.95348 6.38572 8.95558C6.88914 7.56554 8.31863 6.5605 9.99924 6.5605C11.6798 6.5605 13.1087 7.56554 13.6136 8.95558C13.5692 8.95348 13.5244 8.95225 13.4784 8.95225L13.0982 8.99166L13.1222 12.1051H13.7905C13.9494 12.1052 14.1067 12.0728 14.2534 12.0099C14.4002 11.947 14.5336 11.8547 14.6459 11.7383C14.7582 11.6219 14.8473 11.4837 14.9081 11.3316C14.9688 11.1795 15.0001 11.0164 15 10.8518V10.2847C14.9747 9.60663 14.662 9.24038 14.2199 9.07188Z"
      fill="#9A690B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.45332 10.7937C6.45332 8.76345 8.04047 7.11751 9.99924 7.11751C11.958 7.11751 13.5467 8.76415 13.5467 10.7937C13.5467 12.8232 11.9583 14.4697 10.0001 14.4697C8.04182 14.4697 6.45332 12.8239 6.45332 10.7937ZM11.8383 12.3726C11.8804 12.2795 11.8391 12.1698 11.7459 12.1276C11.6527 12.0854 11.543 12.1268 11.5008 12.2199C11.2552 12.7626 10.7884 12.9837 10.3133 12.9854C10.211 12.9857 10.1284 13.0689 10.1287 13.1712C10.1291 13.2735 10.2123 13.3561 10.3145 13.3557C10.9119 13.3537 11.524 13.067 11.8383 12.3726Z"
      fill="#9A690B"
    />
  </svg>
);
