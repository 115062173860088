import React, { forwardRef, useEffect, useState } from 'react';
import { useEffectOnce } from 'lib/useOnMount';
import { usePreviousValue } from 'lib/usePreviousValue';
import { isClientSide } from 'utils/isClientSide';
import * as S from './LightBox.styles';
import { LightBoxProps } from './LightBox.types';

export const DarkenBackground = forwardRef<HTMLDivElement, LightBoxProps>((props, ref) => {
  const { modal = true, isOpen, onClose, skipEnterAnimation, children } = props;
  const [state, setState] = useState({
    active: false,
    animationType: 'skip',
  });

  const prevProps = usePreviousValue(props);

  const enter = (override: string | null = null) => {
    setState({
      active: true,
      animationType: override || 'enter',
    });
  };

  const leave = () => {
    setState({ animationType: 'leave', active: true });
  };

  const close = () => {
    const closeFunc = onClose || leave;

    closeFunc();
  };

  const onKeyUp = (event: KeyboardEvent) => {
    event.stopPropagation();
    if (event.key === 'Escape' && state.active) {
      close();
    }
  };

  const onAnimationEnd = () => {
    const { animationType } = state;

    if (animationType === 'leave') {
      setState({ active: false, animationType });
    }
  };

  useEffectOnce(() => {
    if (isOpen) {
      enter(skipEnterAnimation ? 'skip' : null);
    }
  });

  useEffect(() => {
    if (!prevProps?.isOpen && isOpen) {
      enter();
    } else if (prevProps?.isOpen && !isOpen) {
      leave();
    }
  }, [prevProps, isOpen]);

  useEffect(() => {
    if (isClientSide()) {
      document.documentElement.addEventListener('keyup', onKeyUp);

      return () => {
        if (onkeyup) {
          document.documentElement.removeEventListener('keyup', onkeyup);
        }
      };
    }
  });

  if (!state.active) {
    return null;
  }

  return (
    <S.LightBoxContainer
      ref={ref}
      data-testid="lightBoxContainer"
      animationType={state.animationType}
      onAnimationEnd={onAnimationEnd}>
      {modal && <S.ModalBackground tabIndex={0} data-testid="lightbox-backgrond" />}
      {children}
    </S.LightBoxContainer>
  );
});

DarkenBackground.displayName = 'LightBox';
