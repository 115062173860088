import { createGlobalStyle } from 'styled-components';
import Theme from './theme';

const GlobalStyles = createGlobalStyle`
*,*::before,*::after {
    box-sizing: border-box;
    
  }
  ::-webkit-scrollbar {
    height: 8px;
    width: 8px;
    background: ${Theme.colors.scrollbar.default.background};
    border-left: 0 solid  ${Theme.colors.scrollbar.default.background};
    border-right: 0 solid  ${Theme.colors.scrollbar.default.background};
    border-radius: 4px;
  }
  
  ::-webkit-scrollbar-thumb {
    background: ${Theme.colors.scrollbar.default.thumb};
    border-radius: 4px;
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background: ${Theme.colors.scrollbar.hover.thumb};
  }
  
  ::-webkit-scrollbar-corner {
    background: ${Theme.colors.scrollbar.hover.background};
  }
  
  html {
    scroll-behavior: smooth;
  }
  
  body {
    margin: 0;
    background-color: ${Theme.colors.background};
  }

  a {
    text-decoration: none;
  }

  #nprogress {
    position: fixed;
    top: 0;
    z-index: 999;
    width: 100%;
  }
  
  #nprogress .bar {    
    height: 4px;
    background: ${Theme.color.red.primary};
  }
`;

export default GlobalStyles;
