import styled from 'styled-components';

export const SignInWrapper = styled.div`
  background: ${({ theme }) => theme.primaryColor.white};
  cursor: pointer;
  padding: 0.8rem 8px;
  display: flex;
  height: 40px;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  flex-direction: row;
  position: relative;
`;

export const IconWrapper = styled.div`
  position: absolute;
  left: 4px;
  top: 13px;
  font-size: 1.2rem;
  height: 30px;
  width: 30px;
  color: #000;
`;

export const Title = styled.span`
  color: ${({ theme }) => theme.primaryColor.appBlack};
  font-weight: 500;
  font-size: ${({ theme }) => theme.typography.button.large.size};
`;
