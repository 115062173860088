import { useSearchParam } from 'lib/useSearchParam';
import { useRouter } from 'next/router';

const bannedRoutes = ['sign-up', 'product', 'developer'] as const;

export const useHideMobileLogInOrRegister = () => {
  const { pathname } = useRouter();
  const isBanned = bannedRoutes.some(route => pathname.includes(route));
  const isHideMobileLoginParam = useSearchParam('hideMobileLogin') === 'true';

  return isBanned || isHideMobileLoginParam;
};
