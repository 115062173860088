import NextLink, { LinkProps } from 'next/link';
import { ReactNode } from 'react';
import styled from 'styled-components';

const HiberLink = styled(NextLink).attrs(({ $linkAs }: { $linkAs?: LinkProps['as'] }) => ({ as: $linkAs }))<{
  $linkAs?: LinkProps['as'];
}>`
  color: ${({ theme }) => theme.colors.link.default.text};
  text-decoration: none;
  &:hover {
    color: ${({ theme }) => theme.colors.link.hover.text};
  }
`;

export const Link = ({
  as,
  ...props
}: LinkProps & {
  children: ReactNode;
  passHref?: boolean;
  legacyBehaviour?: boolean;
  target?: '_self' | '_blank';
  rel?: string;
}) => {
  return <HiberLink {...props} $linkAs={as} />;
};
