import { TagType } from 'generated/graphql';
import { postMessageToEngine } from 'lib/postMessageToEngine';
import { isEmbeddedInEngine } from 'lib/useIsEmbeddedInEngine';
import { isClientSide } from 'utils/isClientSide';

type Providers = 'email' | 'google' | 'username' | 'wallet';
export type AccountCreatedEvent = {
  event: 'account_created';
  provider: Providers;
};
type GamePublishedEvent = {
  event: 'game_published';
};
type DeveloperTokenCreatedEvent = {
  event: 'developer_token_created';
};
type UserTaggedSelfEvent = {
  event: 'user_tagged_self';
  name: string;
  type: TagType;
};

type ProductEvent = {
  event: 'view_item' | 'initiate_checkout' | 'purchase';
  product_id: string;
  product_name: string;
  currency: string;
  unit_amount: number;
};

export type GTagEvent =
  | GamePublishedEvent
  | AccountCreatedEvent
  | DeveloperTokenCreatedEvent
  | UserTaggedSelfEvent
  | ProductEvent;

export const gtagRecordEvent = (event: GTagEvent): void => {
  if (!isClientSide()) {
    return;
  }

  const url = new URL(window.location.href);
  const eventSource = url.searchParams.get('eventSource') ?? '';

  if (isEmbeddedInEngine(eventSource)) {
    postMessageToEngine({ type: 'gtagRecordEvent', value: event });
    return;
  }

  const dataLayer = window.dataLayer ?? [];

  dataLayer.push(event);
};
