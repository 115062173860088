import { ButtonText } from 'components/typography';
import styled from 'styled-components';

export const Label = styled(ButtonText).attrs({ size: 'large', as: 'span' })`
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 0.6rem 1.75rem;
  gap: 1rem;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.navigation.hoverBackground};
  }
`;
