import { FEATURE_TOGGLE_ENABLE_WALLET_AUTHENTICATION } from 'constants/featureToggle.constants';
import { useWalletQuery } from 'generated/graphql';
import { useFeatureToggle } from 'lib/useFeatureToggles';
import { useEffect, useState } from 'react';
import { isClientSide } from 'utils/isClientSide';

const LOCAL_STORAGE_KEY = '@hiberworld.walletEnabled';
const QUERY_PARAM = 'walletEnabled';

export const useWalletEnabled = () => {
  const [enabled, setEnabled] = useState(false);
  const { data } = useWalletQuery({ skip: !enabled });
  const walletAuthenticationEnabled = useFeatureToggle(FEATURE_TOGGLE_ENABLE_WALLET_AUTHENTICATION).isEnabled;
  const walletEnabledParam = isClientSide() && new URLSearchParams(window.location.search).get(QUERY_PARAM);
  const hasWallets = Boolean(data?.me?.wallets && data?.me.wallets.length > 0);

  useEffect(() => {
    // If wallet feature toggle disabled -> disable for all users
    if (!walletAuthenticationEnabled) {
      return setEnabled(false);
    }

    // If user already has wallet linked to hiber account -> enable
    if (hasWallets) {
      localStorage.setItem(LOCAL_STORAGE_KEY, 'true');
      return setEnabled(true);
    }

    // If explicitly enabled via query param -> enable and save setting to local storage
    if (walletEnabledParam === 'true') {
      localStorage.setItem(LOCAL_STORAGE_KEY, 'true');
      return setEnabled(true);
    }

    // If explicitly disabled via query param -> disable and save setting to local storage
    if (walletEnabledParam === 'false') {
      localStorage.removeItem(LOCAL_STORAGE_KEY);
      return setEnabled(false);
    }

    // If not explicitly set via query param -> check local storage
    if (localStorage.getItem(LOCAL_STORAGE_KEY) === 'true') {
      return setEnabled(true);
    }
  }, [hasWallets, walletAuthenticationEnabled, walletEnabledParam]);

  return { enabled };
};
