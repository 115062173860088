import { FlexBox } from 'components/layouts/FlexBox';
import * as S from './Tooltip.styles';
import { TooltipProps } from './Tooltip.types';

export const Tooltip = ({ text, children, xOffset, reverse = false }: TooltipProps) => {
  return (
    <S.Container data-testid="tooltip-container">
      <FlexBox alignItems="center" justifyContent="center">
        {children}
        <S.LabelWrapper reverse={reverse} translateX={xOffset ?? 0} data-testid="tooltip">
          <S.Label reverse={reverse} size="medium">
            {text}
          </S.Label>
        </S.LabelWrapper>
      </FlexBox>
    </S.Container>
  );
};
