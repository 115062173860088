import styled, { css, keyframes } from 'styled-components';

const enter = keyframes`
    from {
      opacity: 0;
      transform: scale(.98);
    }
    to {
      transform: scale(1);
      opacity: 1;
    }
`;

const leave = keyframes`
    from {
      opacity: 1;
      transform: scale(1);
    }
    to {
      transform: scale(.98);
      opacity: 0;
    }
`;

const animation = css<{ animationType: string }>`
  animation: ${({ animationType }) => (animationType === 'enter' ? enter : leave)} both ease 200ms;
`;

export const LightBoxContainer = styled.div<{ animationType: string }>`
  ${({ animationType }) => (animationType === 'skip' ? '' : animation)}

  z-index: 9999;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
`;

const noWrapperCSS = css`
  background: #1b1c2033;
  height: 100%;
  margin: 0px;
  max-height: 100%;
  width: 100%;
`;

export const ModalWrapper = styled.div<{ noWrapper?: boolean }>`
  background-color: #292a31;
  padding: 0px;
  opacity: 1;
  margin: 0px 1rem;
  box-shadow: rgba(0, 0, 0, 0.8) 3px 7px 60px;
  border-radius: 8px;
  transform: scale(1);
  min-width: 300px;
  position: relative;
  ${({ noWrapper }) => noWrapper && noWrapperCSS}
  ::-webkit-scrollbar {
    background: #24252b; // TODO: Use theme again, it was undefined here, and it would be nice if it was defined
  }
`;

export const OverflowWrapper = styled.div<{ fullHeight?: boolean }>`
  overflow: auto;
  max-height: ${({ fullHeight }) => (fullHeight ? '96vh' : '90vh')};
`;

export const ModalBackground = styled.div`
  position: absolute;
  background-color: #000;
  left: 0;
  top: 0;
  opacity: 0.9;
  width: 100%;
  height: 100%;
`;

export const CloseButton = styled.div`
  position: absolute;
  cursor: pointer;
  width: 40px;
  height: 40px;
  min-width: 20px;
  top: 40px;
  right: 20px;
  background-color: ${({ theme }) => theme.primaryColor.appDarkGrayLighter};
  backdrop-filter: blur(8px);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
