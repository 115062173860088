import { Link } from 'components/common/Link';
import { MenuItemProps } from './HamburgerMenu.types';
import * as S from './MenuItem.styles';

export const MenuItem = ({ href, Icon, onClick, target = '_self', tabAble, label, hidden }: MenuItemProps) => {
  if (hidden) {
    return null;
  }

  const labelElement = (
    <S.Label tabIndex={tabAble} data-cy={`${label} Menu Item`} onClick={onClick}>
      {Icon} {label}
    </S.Label>
  );

  if (!href) {
    return labelElement;
  }

  return (
    <Link prefetch={false} target={target} href={href}>
      {labelElement}
    </Link>
  );
};
