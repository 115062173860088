import styled from 'styled-components';

export const HiberInput = styled.input`
  background-color: ${({ theme }) => theme.colors.input.default.background};
  border: ${({ theme }) => theme.colors.input.default.border};
  color: ${({ theme }) => theme.colors.input.default.text};
  font-size: ${({ theme }) => theme.typography.body.medium.size.web};
  font-weight: ${({ theme }) => theme.typography.body.medium.weight};
  line-height: ${({ theme }) => theme.typography.body.medium.lineHeight};
  font-style: ${({ theme }) => theme.typography.body.medium.style};
  border-radius: 8px;
  box-shadow: none;
  padding: 0.75rem;
  position: relative;
  resize: none;
  vertical-align: top;
  width: 100%;
  &:focus {
    background-color: ${({ theme }) => theme.colors.input.focus.background};
    border: ${({ theme }) => theme.colors.input.focus.border};
    color: ${({ theme }) => theme.colors.input.focus.text};
  }
  &:focus-visible {
    outline: none;
  }
  &::placeholder {
    color: ${({ theme }) => theme.colors.input.default.placeholder};
  }

  &:invalid {
    background-color: ${({ theme }) => theme.colors.input.error.background};
    border: ${({ theme }) => theme.colors.input.error.border};
    color: ${({ theme }) => theme.colors.input.error.text};
  }
`;
