import { Button } from 'components/common/buttons/Button';
import { FlexBox } from 'components/layouts/FlexBox';
import { Link } from 'components/common/Link';
import { Heading, Paragraph } from 'components/typography';
import { Box } from 'components/layouts/Box/Box';
import { Dialog, DialogProps } from 'components/layouts/Dialog/Dialog';
import { format } from 'date-fns';

import { Suspension } from 'generated/graphql';
import { useDialog } from 'hooks/useDialog';
import { useTheme } from 'styled-components';

type SuspensionModalProps = {
  suspension: Suspension;
} & DialogProps;

export const SuspensionDialog = ({ suspension, ...props }: SuspensionModalProps) => {
  const dialog = useDialog();
  const theme = useTheme();
  const close = () => {
    props?.onClose?.();
    dialog.close(props.id);
  };

  return (
    <Dialog {...props} stayOpenOnOutsideClick>
      <Box maxWidth="600px">
        <Heading size="h3" marginBottom="1rem">
          You have been suspended
        </Heading>
        <Paragraph size="medium">
          We have found your behavior a violention against our{' '}
          <Link target="_blank" href="https://hiber3d.com/terms-of-use">
            Terms of Use
          </Link>
          , and you are suspended until {format(new Date(suspension.endedAt), 'MMM d, yyyy, k:mm')}
        </Paragraph>
        <Paragraph size="medium" marginTop="1rem">
          During your suspension you can still play games, but you&apos;re not allowed to:
        </Paragraph>

        <ul style={{ color: theme.colors.text.default }}>
          <li>
            <Paragraph size="medium">Comment on games or reply to comments</Paragraph>
          </li>
          <li>
            <Paragraph size="medium">Create new Worlds</Paragraph>
          </li>
          <li>
            <Paragraph size="medium">Publish or Edit Worlds</Paragraph>
          </li>
          <li>
            <Paragraph size="medium">Update your profile picture</Paragraph>
          </li>
          <li>
            <Paragraph size="medium">Send or recieve messages in the chat</Paragraph>
          </li>
        </ul>

        <Paragraph size="medium">{suspension.customMessage}</Paragraph>
        <FlexBox justifyContent="center" marginTop="2rem">
          <Button variety="primary" size="medium" text="I understand" onClick={close} />
        </FlexBox>
      </Box>
    </Dialog>
  );
};
