import { Bell } from '@hiberworld/icons';
import { Button } from 'components/common/buttons/Button';
import { FlexBox } from 'components/layouts/FlexBox';
import { CirclePlaceholder, LoadingCard } from 'components/common/SkeletonLoader';
import { Spacer } from 'components/layouts/Spacer';
import { Heading } from 'components/typography';
import { useDive } from 'hooks/useDive';
import { useNotifications } from 'hooks/useNotifications';
import { useNotificationsAmount } from 'hooks/useNotificationsAmount';
import { usePressOutside } from 'hooks/usePressOutside';
import { useUpdateVisitedStatus } from 'hooks/useUpdateVisitedStatus';
import { useRouter } from 'next/router';
import { UIEventHandler, useRef, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { Notification } from './Notification';
import * as S from './NotificationsFeed.styles';
import { NotificationIcon } from 'views/feeds/NotificationsFeed/NotificationIcon';
import { Box } from 'components/layouts/Box/Box';

const AnimatedBell = styled(Bell)<{ open: boolean }>`
  display: block;
  transition: ${({ theme }) => theme.transition.fast};
  color: ${({ open, theme }) => (open ? theme.primaryColor.white : theme.primaryColor.appLightGray)};
  &:hover {
    color: ${({ theme }) => theme.primaryColor.white};
  }
`;

const NotficationBox = styled(Box)`
  border-width: 0 1px;
  padding: 0 0.5rem;

  @media (max-width: ${({ theme }) => theme.breakpoint.medium}) {
    border-width: 0;
    padding: 0;
  }
`;

const ANIMATION_TIME = 1.2;
const NUMBER_OF_ANIMATION_CARDS = 9;

const randomNumber = (min: number, max: number) => Math.floor(Math.random() * (max - min + 1) + min);

export const NotificationsFeed = () => {
  const { updateVisitedStatus } = useUpdateVisitedStatus();
  const [isRefetching, setIsRefetching] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const { amount, setNotificationsAmountToZero } = useNotificationsAmount();
  const [open, setOpen] = useState(false);
  const { notifications, fetchMore, loading, refetch } = useNotifications({
    skip: !open,
  });
  const router = useRouter();
  const theme = useTheme();
  const dive = useDive();
  const closeFeed = () => setOpen(false);
  const containerRef = usePressOutside({
    callback: closeFeed,
  });

  const toggleFeed = async e => {
    e.stopPropagation();
    if (!open) {
      dive.trackUserInteract('', router.asPath, 'notification_open', 'module');
    }
    setOpen(open => !open);

    if (amount !== 0 && !open) {
      setIsRefetching(true);
      refetch().then(() => {
        setIsRefetching(false);
        setNotificationsAmountToZero();
      });
    }
  };

  const edges = notifications?.edges ?? [];
  const pageInfo = notifications?.pageInfo;

  const markAllAsRead = async () => {
    if (edges.filter(e => !e.visited).length === 0) {
      return;
    }

    await updateVisitedStatus(null, true);
  };

  const handleScroll: UIEventHandler<HTMLDivElement> = e => {
    e.stopPropagation();

    const hasReachedBottom =
      ref.current && ref.current.scrollTop >= ref.current.scrollHeight - ref.current.offsetHeight - 35;
    const fetchMoreResults = pageInfo?.hasNextPage && !loading && hasReachedBottom;

    if (fetchMoreResults) {
      fetchMore();
    }
  };

  const isLoading = loading || isRefetching;
  const placeholderAmount = edges.length > 0 && isLoading ? amount : NUMBER_OF_ANIMATION_CARDS;

  return (
    <Box position="relative" data-cy="topBarNotificationsBell">
      <NotficationBox cursor="pointer" border={`1px solid ${theme.colorTheme.greyscale.dark}`}>
        <NotificationIcon />
        <AnimatedBell
          role="button"
          size={40}
          title="Notifications"
          onClick={toggleFeed}
          open={open}
          color={open ? theme.primaryColor.white : theme.primaryColor.appLightGray}
        />
      </NotficationBox>
      <S.NotificationsFeedContainer open={open} ref={containerRef} onScroll={e => e.stopPropagation()}>
        <S.NotificationHeader padding="0.5rem 0rem 0.5rem 1rem" justifyContent="space-between" alignItems="center">
          <Heading size="h5">Notifications</Heading>
          <Button onClick={markAllAsRead} disabled={loading} text="Mark all as read" variety="text" size="medium" />
        </S.NotificationHeader>
        <S.NotificationContent ref={ref} onScroll={handleScroll}>
          {isLoading &&
            [...new Array(placeholderAmount)].map((_, index) => {
              return (
                <FlexBox padding="1rem" data-testid="notifications-loading" key={`${index}-notification-loading-card`}>
                  <CirclePlaceholder size={40} animationTime={ANIMATION_TIME} />
                  <Spacer width={16} />
                  <FlexBox direction="column" width="50%">
                    <LoadingCard
                      width={randomNumber(160, 300)}
                      height={16}
                      animationTime={ANIMATION_TIME}
                      borderRadius={8}
                    />
                    <Spacer height={4} />
                    <LoadingCard
                      width={randomNumber(160, 300)}
                      height={16}
                      animationTime={ANIMATION_TIME}
                      borderRadius={8}
                    />
                    <Spacer height={4} />
                    <LoadingCard
                      width={randomNumber(160, 300)}
                      height={16}
                      animationTime={ANIMATION_TIME}
                      borderRadius={8}
                    />
                  </FlexBox>
                </FlexBox>
              );
            })}
          {edges.map(notification => (
            <Notification
              closeNotifications={closeFeed}
              updateVisitedStatus={updateVisitedStatus}
              key={`${notification.id}-${notification.type}`}
              notification={notification}
            />
          ))}
        </S.NotificationContent>
      </S.NotificationsFeedContainer>
    </Box>
  );
};
