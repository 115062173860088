import { FlexBox } from 'components/layouts/FlexBox';
import { TOPBAR_HEIGHT } from 'views/global/TopBar/TopBar.styles';
import styled from 'styled-components';

export const NotificationsFeedContainer = styled.div<{ open: boolean }>`
  position: absolute;
  border-radius: 0.5rem;
  width: 100vw;
  max-width: 420px;
  height: 70vh;
  right: 0;
  top: calc(${TOPBAR_HEIGHT}px + 0.5rem);
  overflow: hidden;
  background: ${({ theme }) => theme.colorTheme.greyscale.almostBlack};
  display: ${({ open }) => (open ? 'block' : 'none')};

  white-space: normal;
  box-shadow: ${({ theme }) => theme.boxShadow.menu};

  @media screen and (max-width: ${({ theme }) => theme.breakpoint.mediumSmall}) {
    position: fixed;
    right: 0px;
    top: ${TOPBAR_HEIGHT}px;
    height: calc(100dvh - ${TOPBAR_HEIGHT}px);
    border-radius: 0;
    max-width: 100%;
  }
`;

export const NotificationContent = styled.div`
  overflow: auto;
  max-height: 100%;
  padding-bottom: 57px;
`;

export const NotificationHeader = styled(FlexBox)`
  border-bottom: 1px solid ${({ theme }) => theme.colorTheme.greyscale.veryDark};
  background: ${({ theme }) => theme.colorTheme.greyscale.veryDark};
`;
