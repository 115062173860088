import React from 'react';
import formatDistance from 'date-fns/formatDistance';

type RelativeDateProps = {
  value: number | Date;
};

const RelativeDate: React.FC<RelativeDateProps> = ({ value = 0 }) => {
  const now = Date.now();

  value = +value || value || now; // if timestamp: cast to number
  const timeValue = new Date(value).getTime();

  return <>{formatDistance(timeValue, now, { addSuffix: true })}</>;
};

export default RelativeDate;
