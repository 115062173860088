import cx from 'classnames';
import { TextInput } from 'components/inputs/TextInput/TextInput';
import React, { InputHTMLAttributes } from 'react';
import { ControllerRenderProps, FieldError, FieldValues } from 'react-hook-form';
import styled from 'styled-components';
import { Spacer } from 'components/layouts/Spacer';
import { ErrorMarker } from './ErrorMarker';

type InputFieldProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  input: Partial<ControllerRenderProps<FieldValues, any>>;
  label?: string;
  type?: InputHTMLAttributes<HTMLInputElement>['type'];
  ariaLabel?: string;
  meta: { touched: boolean; error?: FieldError };
  className?: string;
  autoComplete?: string;
  disabled?: boolean;
  id?: string;
};

const InputField: React.FC<InputFieldProps> = ({
  input,
  label,
  type,
  ariaLabel,
  meta: { touched, error },
  className,
  autoComplete,
  disabled = false,
  id,
}) => {
  return (
    <div className="field-wrapper">
      <div>
        {disabled && (
          <TextInput
            id={id}
            placeholder={label}
            type={type}
            readOnly
            disabled
            aria-label={ariaLabel}
            value={input.value}
            autoComplete={autoComplete || ''}
            className={cx(className, touched && error && 'dator-error-marker', 'disabled')}
          />
        )}
        {!disabled && (
          <>
            <TextInput
              {...input}
              id={id}
              placeholder={label}
              type={type}
              aria-label={ariaLabel}
              autoComplete={autoComplete || ''}
              className={cx(className, touched && error && 'dator-error-marker')}
            />
            <Spacer height="0.25rem" />
            {touched && error && error[0] && <ErrorMarker message={error[0].defaultMessage} />}
            {error && error?.message && <ErrorMarker message={error?.message} />}
          </>
        )}
      </div>
    </div>
  );
};

export const StyledInput = styled(InputField)``;

export default InputField;
