import styled, { CSSProperties } from 'styled-components';

export type BoxProps = Pick<
  CSSProperties,
  | 'alignItems'
  | 'alignSelf'
  | 'columnGap'
  | 'flex'
  | 'flexBasis'
  | 'flexFlow'
  | 'flexShrink'
  | 'gap'
  | 'height'
  | 'justifyContent'
  | 'maxHeight'
  | 'maxWidth'
  | 'minHeight'
  | 'order'
  | 'rowGap'
  | 'width'
  | 'textAlign'
  | 'position'
  | 'padding'
  | 'paddingBottom'
  | 'paddingLeft'
  | 'paddingRight'
  | 'paddingTop'
  | 'margin'
  | 'marginBottom'
  | 'marginLeft'
  | 'marginRight'
  | 'marginTop'
  | 'flexGrow'
  | 'flexWrap'
  | 'opacity'
  | 'pointerEvents'
  | 'borderRadius'
  | 'backgroundColor'
  | 'overflow'
  | 'overflowX'
  | 'overflowY'
  | 'top'
  | 'left'
  | 'right'
  | 'bottom'
  | 'zIndex'
  | 'justifySelf'
  | 'alignContent'
  | 'borderTop'
  | 'border'
  | 'borderWidth'
  | 'aspectRatio'
  | 'cursor'
  | 'background'
> & {
  scrollBar?: boolean;
};

export const Box = styled.div<BoxProps>`
  align-items: ${({ alignItems }) => alignItems || ''};
  align-self: ${({ alignSelf }) => alignSelf || ''};
  column-gap: ${({ columnGap }) => columnGap || ''};
  flex: ${({ flex }) => flex || ''};
  flex-basis: ${({ flexBasis }) => flexBasis || ''};
  flex-flow: ${({ flexFlow }) => flexFlow || ''};
  flex-wrap: ${({ flexWrap }) => flexWrap || ''};
  flex-shrink: ${({ flexShrink }) => flexShrink || ''};
  gap: ${({ gap }) => gap || 0};
  justify-content: ${({ justifyContent }) => justifyContent || ''};
  max-height: ${({ maxHeight }) => maxHeight || ''};
  max-width: ${({ maxWidth }) => maxWidth || ''};
  min-height: ${({ minHeight }) => minHeight || ''};
  order: ${({ order }) => order || ''};
  row-gap: ${({ rowGap }) => rowGap || ''};
  width: ${({ width }) => width || ''};
  height: ${({ height }) => height || ''};
  text-align: ${({ textAlign }) => textAlign || ''};
  position: ${({ position }) => position || ''};
  padding: ${({ padding }) => padding || ''};
  padding-bottom: ${({ paddingBottom }) => paddingBottom || ''};
  padding-left: ${({ paddingLeft }) => paddingLeft || ''};
  padding-right: ${({ paddingRight }) => paddingRight || ''};
  padding-top: ${({ paddingTop }) => paddingTop || ''};
  margin: ${({ margin }) => margin || ''};
  margin-bottom: ${({ marginBottom }) => marginBottom || ''};
  margin-left: ${({ marginLeft }) => marginLeft || ''};
  margin-right: ${({ marginRight }) => marginRight || ''};
  margin-top: ${({ marginTop }) => marginTop || ''};
  opacity: ${({ opacity }) => opacity || ''};
  pointer-events: ${({ pointerEvents }) => pointerEvents || ''};
  border-radius: ${({ borderRadius }) => borderRadius || ''};
  background-color: ${({ backgroundColor }) => backgroundColor || ''};
  overflow: ${({ overflow }) => overflow || ''};
  overflow-x: ${({ overflowX }) => overflowX || ''};
  overflow-y: ${({ overflowY }) => overflowY || ''};
  flex-grow: ${({ flexGrow }) => flexGrow || ''};
  position: ${({ position }) => position || ''};
  top: ${({ top }) => top || ''};
  left: ${({ left }) => left || ''};
  right: ${({ right }) => right || ''};
  bottom: ${({ bottom }) => bottom || ''};
  z-index: ${({ zIndex }) => zIndex || ''};
  justify-self: ${({ justifySelf }) => justifySelf || ''};
  align-content: ${({ alignContent }) => alignContent || ''};
  border-top: ${({ borderTop }) => borderTop || ''};
  aspect-ratio: ${({ aspectRatio }) => aspectRatio || ''};
  cursor: ${({ cursor }) => cursor || ''};
  background: ${({ background }) => background || ''};
  border: ${({ border }) => border || ''};
  border-width: ${({ borderWidth }) => borderWidth || ''};

  ::-webkit-scrollbar {
    display: ${({ scrollBar }) => (scrollBar ? 'block' : 'none')};
  }
`;
