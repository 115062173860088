import { Chevron } from '@hiberworld/icons';
import cx from 'classnames';
import { FlexBox } from 'components/layouts/FlexBox';
import { Spacer } from 'components/layouts/Spacer';
import React from 'react';
import { ControllerRenderProps, FieldError, Path, FieldValues } from 'react-hook-form';
import styled, { useTheme } from 'styled-components';
import ErrorMarker from './ErrorMarker';

const DownIcon = styled(Chevron)`
  position: absolute;
  right: 1rem;
  top: 1rem;
  rotate: 180deg;
  path {
    stroke-width: 3px;
  }
  pointer-events: none;
`;

export const Select = styled.select`
  position: relative;
  border-radius: 8px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.input.default.background};
  font-size: ${({ theme }) => theme.typography.body.medium.size.web};
  font-weight: ${({ theme }) => theme.typography.body.medium.weight};
  line-height: ${({ theme }) => theme.typography.body.medium.lineHeight};
  font-style: ${({ theme }) => theme.typography.body.medium.style};
  color: ${({ theme }) => theme.primaryColor.appLightGray};
  text-align: left;
  padding: 0.75rem 1.25rem;
  outline: none;
  appearance: none;
  border: none;
  &:focus {
    background-color: ${({ theme }) => theme.colors.input.focus.background};
    border: ${({ theme }) => theme.colors.input.focus.border};
    color: ${({ theme }) => theme.colors.input.focus.text};
  }
  &:focus-visible {
    outline: none;
  }
  &::placeholder {
    color: ${({ theme }) => theme.colors.input.default.placeholder};
  }
`;

interface SelectFieldProps<T extends FieldValues, N extends Path<T>> {
  name?: Path<T>;
  input: ControllerRenderProps<T, N> & { id?: string };
  ariaLabel?: string;
  meta: { touched: boolean; error?: FieldError };
  className?: string;
  onlyValue?: boolean;
  disabled?: boolean;
  children?: React.ReactNode;
}

export const SelectField = ({
  children,
  name,
  input,
  ariaLabel,
  meta: { error },
  className,
  onlyValue,
  disabled,
}: SelectFieldProps<typeof input, typeof name>) => {
  const theme = useTheme();
  return (
    <div className="field-wrapper">
      <FlexBox position="relative" direction="column">
        <Select
          {...input}
          aria-label={ariaLabel}
          name={name}
          disabled={disabled}
          className={cx(className, error && 'dator-error-marker', disabled && 'disabled')}
          onChange={onlyValue ? e => input.onChange(parseInt(e.target.value, 10)) : input.onChange}>
          {children}
        </Select>
        <DownIcon color={theme.colorTheme.greyscale.white} />
        <Spacer height="0.5rem" />
        {error?.message && <ErrorMarker message={error?.message} />}
      </FlexBox>
    </div>
  );
};

export default SelectField;
