import { Label } from 'components/typography';
import styled from 'styled-components';
import { SmallMenuItemStyleProps } from './HamburgerMenu.types';

export const SmallMenuItem = styled(Label).attrs({ size: 'medium', as: 'span' })<SmallMenuItemStyleProps>`
  display: flex;
  align-items: center;
  gap: 4px;
  white-space: nowrap;
  padding: 0.175rem 0;
  cursor: pointer;
  color: ${({ theme, red }) => (red ? theme.primaryColor.red : theme.colorTheme.greyscale.veryLightGrey)};

  &:hover {
    color: ${({ theme }) => theme.colorTheme.greyscale.white};
  }
`;
