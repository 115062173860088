import styled from 'styled-components';

export const PageContentWrapper = styled.div<{
  fullWidth?: boolean;
  noHorizontalPadding?: boolean;
}>`
  max-width: ${({ theme, fullWidth }) => (fullWidth ? 'unset' : theme.maxWidth)};
  margin: 0 auto;
  padding: ${({ noHorizontalPadding }) => (noHorizontalPadding ? '0' : '0 2rem')};

  @media (max-width: ${({ theme }) => theme.breakpoint.mediumLarge}) {
    padding: ${({ noHorizontalPadding }) => (noHorizontalPadding ? '0' : '0 1rem')};
  }

  @media (max-width: ${({ theme }) => theme.breakpoint.medium}) {
    padding: ${({ noHorizontalPadding }) => (noHorizontalPadding ? '0' : '0 0.75rem')};
  }
`;
