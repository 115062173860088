import { User } from '@hiberworld/icons';
import { Spacer } from 'components/layouts/Spacer';
import React from 'react';
import { useTheme } from 'styled-components';
import * as S from './SignUpButton.styles';

type SignUpButtonProps = {
  title: string;
  onClick?: () => void;
};

export const SignUpButton = ({ title, onClick }: SignUpButtonProps) => {
  const theme = useTheme();
  return (
    <S.SignInWrapper onClick={onClick} data-cy="signUpButton">
      <User size={24} color={theme.primaryColor.appBlack} />
      <S.Title>{title}</S.Title>
      <Spacer width={24} />
    </S.SignInWrapper>
  );
};
