import { FlexBox } from 'components/layouts/FlexBox';
import styled from 'styled-components';

export const NotificationContainer = styled.div`
  position: relative;
  background: ${({ theme }) => theme.colorTheme.greyscale.almostBlack};
`;

export const Notification = styled(FlexBox)`
  border-bottom: 1px solid ${({ theme }) => theme.colorTheme.greyscale.veryDark};

  &:hover {
    background: ${({ theme }) => theme.colorTheme.greyscale.veryDark};
  }
  span {
    color: ${({ theme }) => theme.colors.text.default};
  }
`;

export const Thumbnail = styled.div`
  width: 100px;
  height: 56px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 1);
  background-size: cover;
  border-radius: 8px;
  position: absolute;
  right: 1rem;
  top: 0.75rem;
  @media screen and (max-width: 400px) {
    width: 80px;
  }
`;

export const Trophy = styled.div`
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colorTheme.greyscale.white};
`;
